import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
import AuthServices from "./auth.services";

// axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`;


export const getProcessList = () => {
  return axios.get(`${getPrivateURL()}processes` );
};
export const getProcessItem = (process_id) => {
  return axios.get(`${getPrivateURL()}processes/${process_id}`);
};
export const startSimulate = (process_id) => {
  return axios.post(`${getPublicURL()}processes/process_simulate/${process_id}`);
};
export const rerunSimulate = (process_id) => {
  return axios.post(`${getPublicURL()}processes/rerun_simulate/${process_id}` );
};
export const createDraft = () => {
    return axios.post(`${getPublicURL()}processes?device_id=${AuthServices.getDeviceId()}`);
};
export const uploadData = (form_data) => {
    return axios.post(`${getPublicURL()}processes/upload_data`, form_data, {
        headers:
            {
                "Content-Type":"multipart/form-data",
            }} );
};
export const updateProcess = (process_id, data) => {
    return axios.put(`${getPrivateURL()}processes/${process_id}`, data );
};
export const removeProcess = (process_id) => {
    return axios.delete(`${getPrivateURL()}processes/${process_id}` );
};
export const disableProcess = (process_id) => {
    return axios.patch(`${getPrivateURL()}processes/${process_id}` );
};
  

const ProcessService = {
  getProcessList,
  getProcessItem,
  uploadData,
  startSimulate,
  rerunSimulate,
  createDraft,
  updateProcess,
  removeProcess,
  disableProcess,
};
export default ProcessService;

