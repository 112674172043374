
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import {useEffect, useState} from "react";
import PlanService, {getPlanListActive} from "../../../../../services/plan.service";
import {toast} from "react-toastify";
import CheckoutFormGenerator from "../../../../../examples/Checkout";
import AuthServices, {getUserIsLogin} from "../../../../../services/auth.services";
import {getFrontEndURL} from "../../../../../services/core.service";
import authServices from "../../../../../services/auth.services";
// import {hasActivePlan} from "../../../../../services/user.service";


function PricingCards() {

    const [show,setShow] = useState(false);
    const [product,setProduct] = useState("")
    const [plans, setPlans] = useState([])
    useEffect(async () => {
        // if (!hasActivePlan()) {
        //     window.location.href = "/dashboard"
        // }
        PlanService.getPlanListActive()
            .then((data) => {
                if (data.data.data == null) {
                    data.data.data = []
                    window.location.href = "/dashboard"
                }
                setPlans(data.data.data.reverse())
            }).catch((e) => {
                toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            });
    },[setPlans])
  // const [starter, premium, enterprise] = prices;
    const onSelect =(e, m, price)=>{
        if (!getUserIsLogin()){
            window.location.href = "https://dashboard.rbranalytics.io/authentication/register"
            return
        }
        // alert(m);
        // console.log(e.target)
        // console.log(price)
        if (m === "free-id"){
            window.open("https://rbranalytics.io/#contactUs", "_blank");
        } else if(m.toString().indexOf("https://") >= 0){
            window.open(m, "_blank");
        } else{
            setProduct(m);
            setShow(true);
        }
    }
  return (
    <SoftBox position="relative" zIndex={10} mt={{xs: -10, lg:-20}} px={{ xs: 1, sm: 0 }}>
        <CheckoutFormGenerator show={show} product={product}  />
        <Grid container spacing={1} justifyContent="center">
           <Grid item xs={12} lg={2}>
            <DefaultPricingCard
                badge={{ color:"secondary", label:'Free Plan' }}
                price={{ currency: '', value: 'Free' }}
                specifications={[
                    {"label":"10 Days Free", "includes":true},
                    {"label":"1 Analysis per Day", "includes":true},
                    {"label":"Sample Data", "includes":true},
                ]}
                id={"plan_OtFYbxpu79PMNi"}
                todoFunc={onSelect}
                action={{color: "dark", label: getUserIsLogin() ?" Select" : "Sign Up"}}
              />
          </Grid>
           <Grid item xs={12} lg={2}>
            <DefaultPricingCard
                badge={{ color:"secondary", label:'Basic Plan' }}
                price={{ currency: ' ', value: '$1,999', text: '/ year / user' }}
                specifications={[
                    // {"label":"One Year Access", "includes":true},
                    {"label":"Sample Data", "includes":true},
                    {"label":"Guidance Document", "includes":true},
                    {"label":"Unlimited Analyses", "includes":true},
                ]}
                id={"https://buy.stripe.com/test_cN26qSejUgHC3vO9AC?client_reference_id=cus_Ou0sVt9vPihWme" }
                todoFunc={onSelect}
                action={{color: "dark", label: getUserIsLogin() ?" Select" : "Sign Up"}}
              />
          </Grid>
           <Grid item xs={12} lg={2}>
            <DefaultPricingCard
                badge={{ color:"secondary", label:'Corporate Plan' }}
                price={{ currency: ' ', value: '$1,799', text: '/ year / user' }}
                specifications={[
                    {"label":"Available from 10+ Users", "includes":true},
                    {"label":"Sample Data", "includes":true},
                    {"label":"Guidance Document", "includes":true},
                    {"label":"Unlimited Analyses", "includes":true},
                ]}
                id={"free-id"}
                todoFunc={onSelect}
                action={{color: "dark", label: "Contact Us"}}
              />
          </Grid>
           <Grid item xs={12} lg={2}>
            <DefaultPricingCard
                badge={{ color:"secondary", label:'Premium Plan' }}
                price={{ currency: ' ', value: '$3,999', text: '/ year / user'  }}
                specifications={[
                    // {"label":"One Year Access", "includes":true},
                    {"label":"Sample Data", "includes":true},
                    {"label":"Guidance Document", "includes":true},
                    {"label":"Unlimited Analyses", "includes":true},
                    {"label":"5 Hours Technical Reliability Support", "includes":true},
                    {"label":"Additional Technical Support Packages Available for $499/hour", "includes":false},
                ]}
                id={"free-id"}
                todoFunc={onSelect}
                action={{color: "dark", label: "Contact Us"}}
              />
          </Grid>
           <Grid item xs={12} lg={2}>
            <DefaultPricingCard
                badge={{ color:"secondary", label:'Gold Plan' }}
                price={{ currency: '', value: 'Need More?' }}
                specifications={[
                    {"label":"Customized Models", "includes":true},
                    {"label":"Customized Data", "includes":true},
                    {"label":"Customized Plan", "includes":true},
                ]}
                id={"free-id"}
                todoFunc={onSelect}
                action={{color: "dark", label: "Contact Us"}}
              />
          </Grid>
        </Grid>
        <Grid container sx={{"marginTop":"20px"}} spacing={3} justifyContent="center">
            * All taxes are extra to the prices.<br/>
            * Credit Card information is required for all plans.<br/>
            * Free Plan will automatically upgrade to the Basic Plan if not cancelled before 10 days.<br/>
            * In case of plan cancellation during an active plane, the paid fees are non-refundable.<br/>
        </Grid>
    </SoftBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;


 {/*{*/}
          {/*    */}
          {/*    */}
          {/*    */}
          {/*    // plans && plans.map(function (data) {*/}
          {/*    //     // console.log(data)*/}
          {/*    //     let price =  data["price"].toString()*/}
          {/*    //     let currency =  " $ USD"*/}
          {/*    //     let buttonText =  "Subscribe Now"*/}
          {/*    //     if (data["price"] === "0" || data["price"] === 0){*/}
          {/*    //         price = "Contact Us"*/}
          {/*    //         currency = ""*/}
          {/*    //         buttonText = "Contact Us"*/}
          {/*    //     }*/}
          {/*    //*/}
          {/*    //     let fs =  data["items"].map(function (data) {*/}
          {/*    //         return { label: data["title"], includes: data["included"] }*/}
          {/*    //     })*/}
          {/*    //   return (<Grid item xs={12} lg={3}>*/}
          {/*    //     <DefaultPricingCard*/}
          {/*    //       badge={{ color: data["is_promise"] ? "secondary": "warning", label:data["name"] }}*/}
          {/*    //       price={{ currency: currency, value: price }}*/}
          {/*    //       specifications={fs}*/}
          {/*    //       id={data["id"]}*/}
          {/*    //       todoFunc={onSelect}*/}
          {/*    //       action={{color: "dark", label: buttonText,}}*/}
          {/*    //     />*/}
          {/*    //   </Grid>)*/}
          {/*    // })*/}
          {/*}*/}