
import {useEffect, useState} from "react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SubscriptionService, {processPayment} from "../../../services/subscription.service";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";



import tick from "assets/images/tick-green-icon.png";
import cross from "assets/images/cross.jpg";

function AfterPayment() {

    const [waiting, setWaiting] = useState(false);
    const search = useLocation().search;
    const status = new URLSearchParams(search).get('status').replace(/\s/g, "+");
    const product = new URLSearchParams(search).get('product').replace(/\s/g, "+");
    const redirectStatus = new URLSearchParams(search).get('redirect_status').replace(/\s/g, "+");
    const setupIntent = (new URLSearchParams(search).get('setup_intent') || new URLSearchParams(search).get('payment_intent')).replace(/\s/g, "+");
    const setup_intent_client_secret = (new URLSearchParams(search).get('setup_intent_client_secret') || new URLSearchParams(search).get('payment_intent_client_secret')).replace(/\s/g, "+");
    useEffect(()=>{
        setWaiting(true)
        const values ={
            'product': product,
            'status': status,
            'setup_intent': setupIntent,
            'setup_intent_client_secret': setup_intent_client_secret,
            'redirect_status': redirectStatus,
        }

        SubscriptionService.processPayment(values).
            then((data)=>{
                setWaiting(false)
                localStorage.setItem("has_plan", "1");
                toast.success("Process Completed", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            }).catch(e=>{
                setWaiting(false)
                toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            })
    },[])


    const handleAgreeDialog = () => {
        if (!waiting){
            setTimeout(function (p){window.location.href = "/dashboards/payments"}, 1000)
        }
    }

    return (<DashboardLayout>
            <SoftBox pt={3} pb={8} variant={"contained"}  sx={{"textAlign":"center","marginTop":"10rem"}}>
                <SoftBox component="img" src={redirectStatus === "succeeded" ? tick : cross} alt={"RBR"} height="100px" borderRadius="lg" />
                <br/>
                <br/>
                <SoftTypography sx={{"marginBottom":"2rem"}} variant="h2" color={"text"} fontWeight={"bold"} textGradient={true} opacity={0.7} >{redirectStatus} Payment </SoftTypography>
                <br/>
                <SoftTypography sx={{"marginBottom":"2rem"}} variant="h4" color={"primary"} fontWeight={"bold"} textGradient={true} opacity={0.7} >Your Payment Code : {setupIntent} </SoftTypography>

                <br/>

                <SoftTypography sx={{"marginBottom":"2rem", "display": waiting ? "block" : "none"  }}  variant="h6" color={"dark"} fontWeight={"bold"} textGradient={true} opacity={0.7} >Processing Your Subscriptions...</SoftTypography>
                <br/>
                <SoftButton color={"dark"} size={"large"} onClick={handleAgreeDialog} disabled={waiting}>GoTo My Payment Page</SoftButton>

            </SoftBox>
        </DashboardLayout>)
}

export default AfterPayment;
