
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Stack from "@mui/material/Stack";
import SoftButton from "../../components/SoftButton";
import {loadSubscriptionData} from "../applications/data/subscription_data";

import {useEffect, useState} from "react";

import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import LoadingOverlay from 'react-loading-overlay';
import axios from "axios";
import SubscriptionService, {generateToken} from "../../services/subscription.service";
import {toast} from "react-toastify";
import ProcessService from "../../services/process.service";

function Subscriptions() {
    ////////////////////////////////////////////////////  -> Variable
    let selectedId = "";

    ////////////////////////////////////////////////////  -> Default Value and initializer
    const schema = {
        name: Yup.string() .min(4, 'Plan Name must be at least 4 characters') .required('Plan Name is required'),
        description: Yup.string() .min(10, 'Plan Description must be at least 10 characters') .required('Plan Description is required'),
        price:  Yup.number().positive().min(0, "minimum 0").required("price is required"),
        day_count:  Yup.number().required("day count is required"),
    }
    const defaultInputValues = {
        name: "",
        price:0.0,
        description: "",
        day_count: 0,
    };

    ////////////////////////////////////////////////////  -> React Variable
    const [isActiveLoading,setIsActiveLoading] = useState(false);
    const [values, setValues] = useState(defaultInputValues);

    ////////////////////////////////////////////////////    -> Handler

      const [intentId, setIntentId] = useState("");
      const [clientSecret, setClientSecret] = useState("");
      const [errorMessage, setErrorMessage] = useState("");

    // useEffect(()=>{
    //
    // },[setClientSecret])
   const onClickItem = (event) =>{
        console.log(event.target)
        let actionType = event.target.getAttribute("data-type")
        selectedId = event.target.getAttribute("data-row")
       if(actionType === "rerun"){
            if (confirm("do you want to rerun this process")){
                 ProcessService.startSimulate(selectedId).
                    then((data)=>{
                        toast.info("process started", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                        setTimeout( function (){
                            window.location.reload();
                        }, 1500);
                  }).catch((e)=>{
                      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                  });
            }
        }else if(actionType === "download"){
            downloadData(selectedId)
        }else if(actionType === "report"){
            window.location.href = "/applications/report?id=" + selectedId
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        // setErrorMessage("");
        //
        // // Collect payment details from the form (e.g., card details, billing info)
        // const paymentDetails = {
        //   // Add your payment details here
        // };
        // const fd = new FormData();
        // fd.append("client_secret", clientSecret)
        // fd.append("payment_intent_id", intentId)
        // SubscriptionService.processPayment(fd).
        //     then((data)=>{
        //       if (data.data.success) {
        //         // Payment successful
        //         // Display success message to the user
        //       } else {
        //         // Payment failed
        //         // Display error message to the user
        //       } }).
        //     catch((e)=>{
        //       // Display error message to the user
        //       setErrorMessage("Payment failed");
        //     })


        SubscriptionService.generateToken().then((data)=>{
            toast.info(data.data.status)
        }).catch((e)=>{
            toast.error(e.data )
        })
    };


    return (
        <LoadingOverlay active={isActiveLoading} spinner text='Loading your content...'>

        <DashboardLayout>
          <DashboardNavbar />
          <SoftBox pt={6} pb={3}>
            <SoftBox mb={3}>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    All Subscriptions
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    List subscriptions of users.
                  </SoftTypography>
                </SoftBox>
                <Stack spacing={1} direction="row" >
                  {/*<SoftButton variant="gradient" color="primary" size="small" onClick={handleSubmit}>*/}
                  {/*    + new Subscription*/}
                  {/*  </SoftButton>*/}
                </Stack>
              </SoftBox>
                <DataTable table={loadSubscriptionData(onClickItem)}
                           canSearch
                           isSorted={true}
                           entriesPerPage={{
                               defaultValue: 50,
                               entries: [10, 20, 50] }}
                />
              </Card>
            </SoftBox>
          </SoftBox>
          <Footer />
        </DashboardLayout>
        </LoadingOverlay>
    );
}

export default Subscriptions;
