import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`;

export const getPlanList = () => {
  return axios.get(`${getPrivateURL()}plans` );
};
export const getPlanListActive = () => {
  return axios.get(`${getPrivateURL()}active_plans` );
};
export const getPlanItem = (plan_id) => {
  return axios.get(`${getPrivateURL()}plans/${plan_id}` );
};
export const insertPlan = (data) => {
    return axios.post(`${getPrivateURL()}plans`, data );
};
export const updatePlan = (plan_id, data) => {
    return axios.put(`${getPrivateURL()}plans/${plan_id}`, data );
};
export const removePlan = (plan_id) => {
    return axios.delete(`${getPrivateURL()}plans/${plan_id}`);
};
export const disablePlan = (plan_id) => {
    return axios.patch(`${getPrivateURL()}plans/${plan_id}` );
};
  

const PlanService = {
  getPlanList,
  getPlanListActive,
  getPlanItem,
  insertPlan,
  updatePlan,
  removePlan,
  disablePlan,
};
export default PlanService;

