import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";

// axios.defaults.withCredentials = true;

axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`;

export const getDashboard = (filter) => {
  console.log("Filter in request is " + filter)
  return axios.get(`${getPrivateURL()}dashboard`);
};


const DashboardService = {
  getDashboard,
};
export default DashboardService;

