
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
import {useEffect, useState} from "react";
import Office from "../../../../../examples/Icons/Office";
import Basket from "../../../../../examples/Icons/Basket";

function OtherFilters2({stepValidator, handleNext}) {
  const [corrosion, setCorrosion] = useState(false);
  const [mechanicalFatigue, setMechanicalFatigue] = useState(false);

  useEffect((e)=>{
    stepValidator(false)
    if (localStorage.getItem("of2") !== undefined && localStorage.getItem("of2") !== null){
      handleSet(null, localStorage.getItem("of2"));
    }
  }, [])

  const handleSet = (e, value) => {

        stepValidator(true);
      localStorage.setItem("of2", value);
      if (value === "corrosion"){
        setCorrosion(!corrosion);
        setMechanicalFatigue(false);
      }
      if (value === "mechanicalFatigue"){
        setCorrosion(false);
        setMechanicalFatigue(!mechanicalFatigue);
      }
      setSelected(value)

     if (e !== null && e !== undefined && e.target !== null && e.target !== undefined){
      handleNext()
    }
  };

   const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    ".material-icons-round":{
        fontSize:"3rem !important",
    },
    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
      backgroundColor: `#69af35 !important`,

    },
    "&:hover": {
      backgroundColor: `#69af35 !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });

    const [selected, setSelected] = useState([])
   function renderItems(){
     return JSON.parse(localStorage.getItem("of2_items")).keys.map(data => {
       return (
           <Grid item xs={12} sm={2}>
                <SoftBox textAlign="center">
                  <SoftButton
                    color="secondary"
                    variant={selected === data.toLowerCase() ? "contained" : "outlined"}
                    onClick={e=>handleSet(e, data.toLowerCase())}
                    sx={customButtonStyles}
                  >
                    <Icon fontSize={"large"} color={"dark"} component={"span"} >check</Icon>
                  </SoftButton>
                  <SoftTypography variant="h6">{data}</SoftTypography>
                </SoftBox>
              </Grid>
       )
     })
   }
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          {/*<SoftTypography variant="h5" fontWeight="regular">*/}
          {/*  Q4.*/}
          {/*</SoftTypography>*/}
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          What is the failure type you want to simulate for your equipment ?
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container  justifyContent="center">
          {renderItems()}
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default OtherFilters2;
