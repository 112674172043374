import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import SoftButton from "../../components/SoftButton";
import SubscriptionService, {getSubscriptionProduct} from "../../services/subscription.service";
import {toast} from "react-toastify";
import SoftTypography from "../../components/SoftTypography";
import {getFrontEndURL} from "../../services/core.service";

const CheckoutForm2 = ({productId}) => {
  const stripe = useStripe();
  const elements = useElements();


  const [errorMessage, setErrorMessage] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // alert(elements)
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }
      let clientSecret = "";
      await SubscriptionService.generateToken(productId)
        .then((data)=>{
            clientSecret = data.data.data["client_secret"]
        }).catch((e)=>{
            toast.error( e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
            setErrorMessage(e.message)
        })

      const option = {
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret,
          confirmParams: {
              return_url: getFrontEndURL() + `order?product=${productId}&status=completed`,
          },
      };
    if (clientSecret.startsWith("seti_")){
        const {error} = await stripe.confirmSetup(option);
    }else{
        const {error} = await stripe.confirmPayment(option);
    }

    if (error) {
      setErrorMessage(error.message);
    } else {
        localStorage.setItem("active_plan", productId)
    }
  };
    return (
        <form onSubmit={handleSubmit} style={{"textAlign":"center"}}>
          <PaymentElement />
          <SoftButton size={"large"} color={"primary"}  sx={{"marginTop":"2rem"}}  type="submit" disabled={!stripe || !elements} onClick={handleSubmit}>
            Start Subscription
          </SoftButton>
          {/* Show error message to your customers */}
          {errorMessage && <SoftTypography color={"error"} fontWeight={"bold"} sx={{"paddingTop":"1rem"}}>{errorMessage}</SoftTypography>}
        </form>
      );
  };




export default function CheckoutForm ({productId}) {

  const stripePromise =  loadStripe("pk_test_51MWzCRBbljgouQ8eN7y5ZyCfbzn4CbiEfGlS95bKWAVo60m2b2rRn7dCOKwXAILzuXQFBgdMVtyQ8qTpD7AQyB1J00eTzFovTS");
  const [options, setOptions] = useState({})
    useEffect(()=>{
        SubscriptionService.getSubscriptionProduct(productId)
        .then((data)=>{
            console.log(data)
            setOptions({
                mode: 'subscription',
                amount: parseInt(data.data.data["amount"]) * 100,
                currency: data.data.data["currency"],
                appearance: {labels: 'floating'},
            })
        }).catch((e)=>{
            toast.error( e.message, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
        })
    },[setOptions])

    // alert(productId)
  return (
      options.hasOwnProperty("mode") && <Elements stripe={stripePromise} options={options}>
        <CheckoutForm2 productId={productId} />
      </Elements>)
}
