
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
import DataTable from "../../../../../examples/Tables/DataTable";
import LoadCSV from "./data";
import {useEffect, useState} from "react";
import { LicenseInfo } from '@mui/x-license-pro';
import AuthServices from "../../../../../services/auth.services";
import ProcessService from "../../../../../services/process.service";
import {toast} from "react-toastify";
import CoreService from "../../../../../services/core.service";

function ReviewData({file, stepValidator}) {
  // alert(file)
  const [errorCount, setErrorCount] = useState(0)
  useEffect(()=>{
    LicenseInfo.setLicenseKey('17eebdffc2075dfbb3ca8235195ecbddTz03NzUwMyxFPTE3Mjk5NjczNjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

    stepValidator(false);
  }, [stepValidator]);

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            View and confirm your data
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Your invalid inputs are in red and orange.
          please check then and fix the problems
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>

            <SoftTypography style={{"margin":"0.5rem"}} variant="caption" fontWeight="regular" color="text">
              Errors Count:
            </SoftTypography>
            <SoftTypography variant="caption" fontWeight="bold" color={errorCount ===0 ?"success":"error"}>
              {errorCount === 0 ? "No Error's" : errorCount + " Error's"}
            </SoftTypography>
            <LoadCSV  file={file} onCompleted={stepValidator} setErrorCount={setErrorCount} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ReviewData;
