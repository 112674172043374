
// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";

function DefaultPricingCard({ id,badge, price, specifications, action, todoFunc }) {
  const renderSpecifications = specifications.map(({ label, includes }) => (
    <SoftBox key={label} display="flex" alignItems="center" p={1} >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minWidth="1.9rem"
        minHeight="1.9rem"
        borderRadius="50%"
        shaodw="md"
        bgColor={includes ? "success" : "secondary"}
        variant="gradient"
        mr={2}
      >
        <SoftTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
          <Icon sx={{ fontWeight: "bold" }}>{includes ? "done" : "info_outline"}</Icon>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="caption" color="text">
        {label}
      </SoftTypography>
    </SoftBox>
  ));

  return (
    <Card sx={{"minHeight":"32rem", "minWidth":"12rem"}}>
      <SoftBox pt={3} pb={2} px={2} textAlign="center">
        <SoftBadge
          variant="contained"
          color={badge.color}
          size="sm"
          badgeContent={badge.label}
          circular
          container
        />
        <SoftBox my={1}>
          <SoftTypography variant="h3">
            {price.value}

            <SoftTypography display="inline" component="small" variant="h4">
              {price.currency}
            </SoftTypography>
            {
                price.currency !== "" && (
                    <SoftTypography sx={{"paddingLeft":"10px", "fontSize":"12px"}}
                                    display="caption" opacity={"0.8"} fontWeight={"light"} component="small" >
                      {price.text}
                    </SoftTypography>)
            }

          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        {renderSpecifications}
        <SoftBox mt={3}>
            <SoftButton sx={{"position":"absolute", "bottom":"20px", "width":"90%"}}
                        variant="gradient" color={action.color} onClick={(e)=>todoFunc(e, id, price.value)} fullWidth>
              {action.label}&nbsp;
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </SoftButton>
          </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  todoFunc: PropTypes.func.isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
