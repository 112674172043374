
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import React, {useCallback, useEffect} from 'react'

import { saveAs } from 'file-saver';
// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
// import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone'
import ProcessService from "../../../../../services/process.service";
import {toast} from "react-toastify";
import AuthServices from "../../../../../services/auth.services";
import SidenavItem from "../../../../../examples/Sidenav/SidenavItem";
import Link from "@mui/material/Link";
import userService from "../../../../../services/user.service";
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

function UploadData({fileSetter, handleNext, stepValidator, handleOpenHelpDialog, type}) {
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles !== undefined && acceptedFiles !== null && acceptedFiles.length > 0){

            do_upload(acceptedFiles[0]);
        }else{
            alert("no file selected")
        }
    }, [fileSetter])
    const GetFileBlobUsingURL = function (url, convertBlob) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function() {
            convertBlob(xhr.response);
        });
        xhr.send();
    };

    const blobToFile = function (blob, name) {
        blob.lastModifiedDate = new Date();
        blob.name = name;
        return blob;
    };

    const GetFileObjectFromURL = function(filePathOrUrl, convertBlob) {
       GetFileBlobUsingURL(filePathOrUrl, function (blob) {
          convertBlob(blobToFile(blob, 'testFile.csv'));
       });
    };
    const do_upload = (file) =>{

        fileSetter(file);
        handleNext();
    }
    useEffect(()=>{
     stepValidator(false);
    })
  const downloadFileInseption = (e) =>{
      let fileName = "Sample Data - Inspection-Test.csv";
      console.log(type);
      const newType = type
          .replaceAll(" -> ", "-")
          .replace("Pressure Vessle", "Pressure")
          .replace("PressureVessle", "Pressure")
      const FileURL = process.env.PUBLIC_URL + "/resources/" + newType + "/" + fileName
      // saveAs(, fileName);

        // var FileURL="test/test.jpg"
        GetFileObjectFromURL(FileURL, function (fileObject) {
             // console.log(fileObject);
             do_upload(fileObject)
        });


  }
  const downloadFileEmpty = (e) =>{
      let fileName = "EmptyCSV.csv";
      // console.log(type);
      const newType = type
          .replaceAll(" -> ", "-")
          .replace("Pressure Vessle", "Pressure")
          .replace("PressureVessle", "Pressure")
      const FileURL = process.env.PUBLIC_URL + "/resources/" + newType + "/" + fileName
      // saveAs(, fileName);

        // var FileURL="test/test.jpg"
        GetFileObjectFromURL(FileURL, function (fileObject) {
             // console.log(fileObject);
             do_upload(fileObject)
        });


  }

  const downloadFile = (e) =>{
      let fileName = "Sample Data - Test.csv";
      const newType = type
          .replaceAll(" -> ", "-")
          .replace("Pressure Vessle", "Pressure")
          .replace("PressureVessle", "Pressure")
      const FileURL = process.env.PUBLIC_URL + "/resources/" + newType + "/" + fileName
      // alert(FileURL)
       GetFileObjectFromURL(FileURL, function (fileObject) {
             // console.log(fileObject);
             do_upload(fileObject)
        });
  }
    const openGuid = (e) =>{
        if(userService.hasActivePlan()){
            handleOpenHelpDialog()
        }else{
            alert("you dont have active plan")
        }
    }
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          {/*<SoftTypography variant="h5" fontWeight="regular">*/}
          {/*  Upload Data*/}
          {/*</SoftTypography>*/}
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text" >
          Populate the input table based on your actual asset data. For more details on the requirements see the
            <SoftButton sx={{"marginLeft":"0.5rem"}} variant={"outlined"} color={"secondary"}
                        onClick={openGuid}>&nbsp;Guidance Document</SoftButton>
        </SoftTypography>
      </SoftBox>
      <SoftBox  mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
                <Dropzone onDrop={onDrop} accept={ {"application/ms-excel":['.csv']}}
                          multiple={false} autoFocus={false} maxFiles={1} inputContent="Drop A File"  >
                    {({ getRootProps, getInputProps }) => {
                      return (
                          <section>
                            <SoftBox sx={baseStyle} {...getRootProps()}>
                              <input {...getInputProps()} />
                              {
                                <SoftBox className='fileDrop'>
                                  + Drag & Drop, or Click Here to Upload the CSV File
                                </SoftBox>
                              }
                            </SoftBox>
                          </section>
                      );
                    }}
                  </Dropzone>


              <div style={{"marginTop":"2rem"}}>
                  <SoftTypography variant="body2" fontWeight="regular"  color="text">
                    If you do not have asset data and would like to see the software functionality use the provided sample data
                  </SoftTypography>
                  <br/>
                  <SoftButton variant={"gradient"} color={"primary"} onClick={downloadFile} sx={{"cursor":"pointer", "marginRight":"2rem"}} >Run Sample Data without Inspection</SoftButton>
                  <SoftButton variant={"gradient"} color={"primary"} onClick={downloadFileInseption} sx={{"cursor":"pointer", "marginRight":"2rem"}} >Run Sample Data with Inspection</SoftButton>
                  <SoftButton variant={"gradient"} color={"primary"} onClick={downloadFileEmpty} sx={{"cursor":"pointer", "marginRight":"2rem"}} >Load Empty</SoftButton>
              </div>


          </Grid>
          {/*<Grid item xs={12} sm={1} marginTop={5}>*/}
          {/*  <SoftButton fullWidth color={"warning"} size={"large"} variant={"gradient"} onClick={downloadFile}><Icon>download</Icon>*/}
          {/*        Download Sample</SoftButton>*/}
          {/*</Grid>*/}
        </Grid>

      </SoftBox>
    </SoftBox>
  );
}

export default UploadData;
