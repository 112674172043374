
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Project page components
import Header from "layouts/pages/profile/components/Header";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";
import logoInvision from "assets/images/small-logos/logo-invision.svg";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import DataTable from "../../../../examples/Tables/DataTable";
import dataTableData from "../../../ecommerce/products/products-list/data/dataTableData";
import {Link} from "react-router-dom";
import SoftButton from "../../../../components/SoftButton";
import Stack from "@mui/material/Stack";
import {loadUserData} from "../../../applications/data/user_data";

function Staffs() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Staffs
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List of all staffs in system
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <Link to="/ecommerce/products/new-product">
                <SoftButton variant="gradient" color="primary" size="small">
                  + new Staff
                </SoftButton>
              </Link>
            </Stack>
          </SoftBox>
          <DataTable
            table={loadUserData("staff")}
            entriesPerPage={{
              defaultValue: 50,
              entries: [5, 10, 15, 20, 50],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Staffs;