
import {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Wizard page components
import About from "layouts/applications/wizard/components/About";
import Account from "layouts/applications/wizard/components/Account";
import Address from "layouts/applications/wizard/components/Address";
import PipelineFacility from "./components/PiplineFacility";
import PipingEquipment from "./components/PipingEquipment";
import OtherFilters from "./components/OtherFilters";
import UploadData from "./components/UploadData";
import ReviewData from "./components/ReviewData";
import OtherFilters2 from "./components/OtherFilters2";
import ProcessService from "../../../services/process.service";
import {toast} from "react-toastify";
import AuthService, { getUserIsLogin} from "../../../services/auth.services";
import coreService, {toPascalCase} from "../../../services/core.service";
import {
    Breadcrumbs,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    TextField
} from "@mui/material";
import Fade from "@mui/material/Fade";
import {ModalStyleHalf} from "../../modalStyle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import UserService, {isAdminLogin, userRole} from "../../../services/user.service";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Image} from "@mui/icons-material";

import brand from "assets/images/logo-ct.png";
import tick from "assets/images/tick-green-icon.png";
import SoftInput from "../../../components/SoftInput";
import Link from "@mui/material/Link";
import FilterType from "./components/FilterType";
import {RenderHelp} from "./help";
function getSteps() {
  return ["Asset Class", "Component", "Sub-component", "Failure Mode", "Category", "UploadData", "Preview"];
}

function Wizard() {
    ////////////////////////////////////////////////////  -> Default Value and initializer
    const schema = {email: Yup.string() .email().required('Email is required'), password:Yup.string().required('Password is required') }
    const defaultInputValues = {email: "", password:"", name:""};
    const [values, setValues] = useState(defaultInputValues);

   ////////////////////////////////////////////////////    -> Variable
    const [processId, setProcessId] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [showNumberModal, setShowNumberModal] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [openHelpDialog, setOpenHelpDialog] = useState(false);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const validationSchema = Yup.object().shape(schema);
    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});
    const [isValid, setIsValid] = useState(false);
    const [isValidStep, setIsValidStep] = useState(false);

    //////////////////////////////////////////////////  -> Function
    const getProcessId = () =>{
    // if (data === undefined ||  data === null || data === ""){
      ProcessService.createDraft().then((data)=>{
        localStorage.setItem("process_id",  data.data["process_id"])
        AuthService.setDeviceId(data.data["device_id"])
            setProcessId(data.data["process_id"])

            handleChange({ ...values, device_id: AuthService.getDeviceId() })
            // toast.success("fetch process id", {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            //   autoClose: 2000,
            //   hideProgressBar: false,
            // });
          }).catch((e)=>{
            toast.error(e.response.data.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
              hideProgressBar: false,
            });
            setTimeout(function (){window.location.href = "/authentication/login"}, 1000)
          })
    }


    const [isBack,setIsBack] = useState(false)
    function getStepContent(stepIndex) {
        const [file, setFile] = useState(null)
        if (localStorage.getItem("next_step") === "of2" && stepIndex === 2){
            stepIndex = stepIndex + 1;
            setActiveStep(stepIndex)
            localStorage.removeItem("of")
            localStorage.removeItem("ft")
        }
        if (localStorage.getItem("next_step") === "ft" && stepIndex === 2){
            stepIndex = 5;
            setActiveStep(stepIndex)
            localStorage.removeItem("of")
            localStorage.removeItem("of2")
            localStorage.removeItem("ft") // for test
        }else if (stepIndex === 4){
                localStorage.removeItem("ft") // for test
            if (localStorage.getItem("of2") === "functional" && localStorage.getItem("of") === "filter"){

                localStorage.removeItem("ft") // for test
                // stepIndex = 5;
            }
            // else if(file === undefined || file === null ){
            //     setActiveStep(stepIndex)
            // }
            else{
                stepIndex = 5;
                setActiveStep(stepIndex)
            }
        }
        // alert(stepIndex)
        switch (stepIndex) {
          case 0:
            return <PipelineFacility stepValidator={setIsValidStep} handleNext={handleNext} />;
          case 1:
            return <PipingEquipment stepValidator={setIsValidStep} activeStepSet={setActiveStep} handleNext={handleNext}  />;
          case 2 :
            return <OtherFilters stepValidator={setIsValidStep} handleNext={handleNext}  />;
          case 3:
            return <OtherFilters2 stepValidator={setIsValidStep} handleNext={handleNext}  />;
          case 4:
            return <FilterType stepValidator={setIsValidStep} handleNext={handleNext}  />;
          case 5:
            return <UploadData stepValidator={setIsValidStep}  fileSetter={setFile} handleNext={handleNext}
                               type={coreService.getProcessType()} handleOpenHelpDialog={handleOpenHelpDialog}  />;
          case 6:
            return <ReviewData   stepValidator={setIsValidStep} handleNext={handleNext}  file={file} />;
          default:
            return null;
        }
    }
    console.log(activeStep)
    //////////////////////////////////////////////////  -> Init
    useEffect(()=>{
        if (processId === ""){
            getProcessId()
        }
        // if(getUserIsLogin() && !isAdminLogin() &&  !getUserHasActivePlan()){
        //     toast.warning("You don't have any active plan", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
        //     setTimeout((e)=>{
        //         window.location.href = "/dashboards/pricing"
        //     }, 3000);
        // }

    },[setProcessId])

    ////////////////////////////////////////////////////    -> Handler
    const handleNext = () => {
        if (!isLastStep){
          setActiveStep(activeStep + 1)
          return;
        }
        handleOpenDialog()
    };
    const [isLoading, setIsLoading] = useState(false);
    const handleAgreeDialog = () => {
        setIsValidStep(false)
       ProcessService.startSimulate(processId).
        then((data)=>{
            toast.info("process started", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            localStorage.removeItem("process_id")
            setTimeout( function (){
              if (getUserIsLogin()){
                window.location.href = "/applications/processes?process=" + processId
              }else{
                handleOpenModal()
              }  }, 1500);
      }).catch((e)=>{
          toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
      });
        handleCloseDialog();
    }


    ////////////////////////////////////////////////////    -> Handler
    const handleOpenHelpDialog = () => setOpenHelpDialog(true);
    const handleCloseHelpDialog = () => setOpenHelpDialog(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenModal = (e) => setShowNumberModal(true)
    const handleCloseModal = () => setShowNumberModal(false)
    const handleBack = () => {
        setActiveStep(0);
    }
    const clickDashboard = (e) =>{
        e.preventDefault()
        window.location.href = "/dashboard"
    }
    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };
    const handleActionClick = () =>{
        if (validationSchema.isValidSync(values)){
            if(loginMode){
                AuthService.loginAfterProcess(values.email, values.password, processId)
                .then((data)=>{
                    AuthService.setPermission(data.data["permission"])

                    localStorage.setItem("access_token", data.data["access_token"])
                    toast.success("login successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                    setTimeout(function () {window.location.href = "/dashboard";}, 1000)
                }).catch((e)=>{
                    console.log(e.response)
                    toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                })
            }else{
                AuthService.register(values)
                .then((data)=>{
                    window.location.href = "/authentication/login?email=" + values.email
                }).catch((e)=>{
                    toast.error(e.response.data.status, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 2000,
                      hideProgressBar: false,
                    });
                })
            }
        }
    }
    ////////////////////////////////////////////////////    -> Render
    const renderDialog = () => {
        return (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"success"} sx={{"paddingRight":3}} >info</Icon>{"Start Process ?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Start Process with this data ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SoftButton onClick={handleCloseDialog} >Disagree</SoftButton>
              <SoftButton onClick={handleAgreeDialog}  size={"large"}  color={"info"}>Yes Start Now</SoftButton>
            </DialogActions>
          </Dialog>
        )
    }
    const renderHelpDialog = () => {
        return (
            <Dialog  maxWidth={"xl"} fullWidth={true} open={openHelpDialog}
                    keepMounted onClose={handleCloseHelpDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"success"} sx={{"paddingRight":3}} >info</Icon>{"Help Dialog"}</DialogTitle>
            <DialogContent  >
              <DialogContentText    id="alert-dialog-slide-description">
                <RenderHelp />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SoftButton color={"dark"} onClick={handleCloseHelpDialog} >Ok, Got It</SoftButton>
            </DialogActions>
          </Dialog>
        )
    }
    const [loginMode, setLoginMode] = useState(false)
    const actionLoginClicked = (e) =>{
        setLoginMode(!loginMode)
        handleChange(defaultInputValues)
    }
    const renderShowNumberModal = () => {
        return (
           <Modal open={showNumberModal}>
                <Fade in={showNumberModal}>
                  <Card sx={ModalStyleHalf}>
                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                          <Box p={2} mb={2}>
                            {/*<Typography variant="h5">Enter Your Email to receive Report</Typography>*/}
                          </Box>
                          <Box component="form" pb={1} px={1}>

                              <Grid container spacing={2}>
                                  <Grid item xs={12} md={6} sx={{"textAlign":"center"}}>
                                    {/*<SoftBox component="img" src={brand} alt="RBR-Dashboard" height="2rem" sx={{"margin":"1rem"}}  />*/}

                                    <SoftBox component="img" src={tick} alt="RBR-Dashboard" height="8rem"  />
                                    <SoftTypography color={"text"}
                                                    fontWeight={"bold"} opacity={0.9} textGradient={true}
                                                    verticalAlign={"top"}>Process Finished <br/>
                                                    Register Your email to receive Report</SoftTypography>
                                      <SoftButton component={"a"} color={"info"} onClick={actionLoginClicked} size={"small"}
                                                  sx={{"marginTop":"10px"}}>{loginMode ? "Back to Register" : "Login With your account"}</SoftButton>
                                  </Grid>
                                  <Grid item xs={12} md={6} >
                                      <Grid container spaceing={4}>
                                          <Grid item xs={12} md={12} p={1} >
                                              <SoftTypography color={"dark"} fontWeight={"bold"} opacity={0.4} textGradient={true}
                                                              verticalAlign={"top"}>Your Email (*):</SoftTypography>
                                            <SoftInput
                                                  fullWidth
                                                  required
                                                  error={!!errors.email}
                                                  helperText={errors.email?.message}
                                                  value={values.email}
                                                  {...register("email")}
                                                  onChange={(event) => {
                                                    handleChange({ ...values, email: event.target.value })
                                                  }}
                                                  label="User Email"
                                                  InputProps={{ autoComplete: "" }}
                                                  placeholder="yourmail@gmail.com"
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={12} p={1} >
                                              <SoftTypography color={"dark"} fontWeight={"bold"} opacity={0.4} textGradient={true}
                                                              verticalAlign={"top"}>Password (*):</SoftTypography>
                                            <SoftInput
                                                  fullWidth
                                                  required
                                                  error={!!errors.password}
                                                  helperText={errors.password?.message}
                                                  value={values.password}
                                                  {...register("password")}
                                                  onChange={(event) => {
                                                    handleChange({ ...values, password: event.target.value })
                                                  }}
                                                  label="User Name"
                                                  type={"password"}
                                                  InputProps={{ autoComplete: "",  }}
                                                  placeholder="Your Password"
                                            />
                                          </Grid>
                                          {
                                              !loginMode && <Grid item xs={12} md={12} p={1} >
                                              <SoftTypography color={"dark"} fontWeight={"bold"} opacity={0.4}
                                                              textGradient={true} verticalAlign={"top"}>Your Name :</SoftTypography>
                                                <SoftInput
                                                      fullWidth
                                                      error={!!errors.name}
                                                      helperText={errors.name?.message}
                                                      value={values.name}
                                                      {...register("name")}
                                                      onChange={(event) => {
                                                        handleChange({ ...values, name: event.target.value })
                                                      }}
                                                      label="User Name"
                                                      InputProps={{ autoComplete: "" }}
                                                      placeholder="Alice Saba"
                                                />
                                              </Grid>
                                          }

                                      </Grid>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <SoftButton fullWidth disabled={!isValid} color={"dark"} size={"large"}
                                                onClick={e => handleActionClick()} >{loginMode ? "Login" : "Send Report To My Email"}</SoftButton>
                                  </Grid>
                            </Grid>
                          </Box>
                      </Card>
                  </Card>
                </Fade>
              </Modal>
        )
    }

  return (
      <SoftBox pt={3} pb={8}>
        <SoftButton sx={{marginLeft:"10px" , "display":(getUserIsLogin() ? "fixed" : "none")}}
                    variant={"gradient"} size={"large"} color={"secondary"} onClick={clickDashboard}>Go to Dashboard</SoftButton>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <SoftBox mt={6} mb={1} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography variant="h3" fontWeight="bold">
                  Risk Based Reliability
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h5" fontWeight="regular" color="secondary">
                   Asset Condition Management Application Platform
              </SoftTypography>
            </SoftBox>
              {renderHelpDialog()}
              {renderDialog()}
              {renderShowNumberModal()}
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (<Step key={label}>
                  <a style={{"cursor":"pointer"}} onClick={e=>setActiveStep(index)}><StepLabel>{label}</StepLabel></a>
                </Step>))}
            </Stepper>
            <Card>
              <SoftBox p={2}>
                <SoftBox container>
                  <Grid container justifyContent="center">
                      <Grid item xs={3} lg={12} display={activeStep === steps.length-1 ? "none" :"block"}>
                        <SoftBox>
                            <Breadcrumbs aria-label="breadcrumb">

                                {toPascalCase(localStorage.getItem("pf")) && activeStep  > 0 &&
                                    <Typography color="text.primary">
                                        <a  onClick={e=>setActiveStep(0)} style={{cursor:"pointer"}}>{toPascalCase(localStorage.getItem("pf"))}</a>
                                    </Typography>
                                }
                                {toPascalCase(localStorage.getItem("pe")) && activeStep  > 1 &&
                                    <Typography color="text.primary">
                                        <a  onClick={e=>setActiveStep(1)} style={{cursor:"pointer"}}>{toPascalCase(localStorage.getItem("pe"))}</a>
                                    </Typography>
                                }
                                {toPascalCase(localStorage.getItem("of")) && activeStep  > 2 &&
                                    <Typography color="text.primary">
                                        <a  onClick={e=>setActiveStep(2)} style={{cursor:"pointer"}}>{toPascalCase(localStorage.getItem("of"))}</a>
                                        {/*{ toPascalCase(localStorage.getItem("of"))}*/}
                                    </Typography>
                                }
                                {toPascalCase(localStorage.getItem("of2")) && activeStep > 3 &&
                                    <Typography color="text.primary">
                                        <a  onClick={e=>setActiveStep(3)} style={{cursor:"pointer"}}>{toPascalCase(localStorage.getItem("of2"))}</a>
                                        {/*{ toPascalCase(localStorage.getItem("of2"))}*/}
                                    </Typography>
                                }
                                {/*{toPascalCase(localStorage.getItem("ft")) && activeStep  > 4 && <Typography color="text.primary">{ toPascalCase(localStorage.getItem("ft"))}</Typography>}*/}
                            </Breadcrumbs>
                        </SoftBox>
                      </Grid>
                      {/*<Grid item xs={11} lg={(activeStep === steps.length-1) ? 12 :10}>*/}
                      <Grid item xs={11} lg={12}>
                        <SoftBox  justifyContent="space-between">
                          {getStepContent(activeStep)}
                        </SoftBox>
                      </Grid>
                  </Grid>
                  <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton variant="gradient" color="light" onClick={handleBack}>
                        Start Over
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="gradient"
                      color="success"
                      disabled={!isValidStep}
                      onClick={handleNext}
                    >
                      {isLastStep ? "Start Simulate" : "Next"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>

                {/*<div style={{"textAlign":"center"}}>*/}
                {/*    <SoftButton size={"medium"}  onClick={handleOpenHelpDialog}*/}
                {/*                style={{"width":"11rem"}}*/}
                {/*                variant={"gradient"} color={"dark"}><Icon style={{"paddingRight":"2rem"}}>help</Icon> Show Help </SoftButton>*/}
                {/*</div>*/}
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
  );
}

export default Wizard;
