
const thinBarChartData = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: {
    label: "Watts",
    data: [150, 230, 380, 220, 420, 200, 70, 500],
  },
};

export default thinBarChartData;
