

import {React, useEffect, useState} from "react";
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";
import { toast } from 'react-toastify';


// Badges
const waitForConfirmedBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="Wait for confirm" container />
);
const removedBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="removed" container />
);
const verifyBadge = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Confirm" container />
);

import { getUsers } from "services/user.service"
import ActionCell from "../../ecommerce/products/products-list/components/ActionCell";
export function loadUserData(role, onClickItem){
  const columns = [
    { Header: "Stripe", accessor: "stripe" },
    { Header: "Username", accessor: "email" },
    { Header: "Create", accessor: "create"},
    { Header: "Name", accessor: "name" },
    { Header: "Location", accessor: "location" },
    { Header: "Phone", accessor: "phone" },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => (value === "confirm" ? verifyBadge : (value === "wait_for_confirm" ? waitForConfirmedBadge : removedBadge)),
    },
    { Header: "action", accessor: "id" ,
      Cell: ({ value }) => {
        return onClickItem !== undefined && <ActionCell onClick={onClickItem} rowId={value}  />
      }
    },
  ]
  const [rows, setRows] = useState([])
  function requestServer(rowsSetter){
    getUsers(role)
      .then((data)=>{
        rowsSetter(data.data.data)
      }).catch((e)=>{
        toast.error(e.response.data.status, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  }
  useEffect(()=>{ requestServer(setRows) }, [setRows]);
  return {
    columns : columns,
    rows: rows
  }
}
