
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import {planData, subscriptionData} from "layouts/applications/data-tables/data/dataTableData";
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";
import SoftButton from "../../../components/SoftButton";
import dataTableData from "../../ecommerce/products/products-list/data/dataTableData";
import uploadDataTableData from "../data/upload_data";

function UploadedData() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Data
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List Of Uploaded Data In System
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <DataTable
            table={uploadDataTableData}
            entriesPerPage={{
              defaultValue: 50,
              entries: [5, 10, 15, 20, 50],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UploadedData;
