
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";
import SoftButton from "../../components/SoftButton";
import {loadPlanData} from "../applications/data/plan_data";
import {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    InputLabel,
    Modal,
    TextField
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormField from "../pages/account/components/FormField";
import {ModalStyleHalf} from "../modalStyle";
import PlanService, {insertPlan} from "services/plan.service";

import { toast } from 'react-toastify';


import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import SoftInput from "../../components/SoftInput";
import LoadingOverlay from 'react-loading-overlay';
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import SoftSelect from "../../components/SoftSelect";
import SoftTagInput from "../../components/SoftTagInput";
import Checkbox from "@mui/material/Checkbox";

function Plans() {

    ////////////////////////////////////////////////////  -> Variable
    let selectedId = "";

    ////////////////////////////////////////////////////  -> Default Value and initializer
    const schema = {
        name: Yup.string() .min(4, 'Plan Name must be at least 4 characters') .required('Plan Name is required'),
        price:  Yup.number().positive().min(0, "minimum 0").required("price is required"),
        total_day_count:  Yup.number().required("total_day_count is required"),
        trial_day_count:  Yup.number(),
        process_limit_per_day:  Yup.number().positive().min(0, "minimum 0"),
        record_limit_per_process:  Yup.number().positive().min(0, "minimum 0"),
        has_adviser_comment:  Yup.bool(),
    }
    const defaultInputValues = {
        name: "",
        price:0.0,
        total_day_count: 30,
        trial_day_count: 0,
        has_adviser_comment: false,
        process_limit_per_day: 0,
        record_limit_per_process: 0,
    };

    ////////////////////////////////////////////////////  -> React Variable
    const [isActiveLoading,setIsActiveLoading] = useState(false);
    const [values, setValues] = useState(defaultInputValues);
    const validationSchema = Yup.object().shape(schema);
    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});
    const [isValid, setIsValid] = useState(false);
    const [open, setOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("Insert");
    const [openDialog, setOpenDialog] = useState(false);

    ////////////////////////////////////////////////////    -> Handler
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenModal = () => {
        setOpen(true)
        if(selectedId !== ""){
            setModalTitle("Edit")
        }else{
            setModalTitle("Insert")
        }
    }
    const handleCloseModal = () => {
        setOpen(false)
        setValues(defaultInputValues)
        setIsValid(false);
    }
    const handleChange = (value) => {
        // console.log(validationSchema.validate(values))
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };
    const handleAgreeDialog = () => {
        if(values.hasOwnProperty("id") && values.id !== undefined  && values.id !== ""){
            setIsActiveLoading(true)
            PlanService.removePlan(values.id)
            .then((data)=>{
              setIsActiveLoading(false)
              toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
              window.location.reload();
            }).catch((e)=>{
                setIsActiveLoading(false)
                toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
            });
        }else{
            toast.warning("You don't select any record", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
        }
        handleCloseDialog();
    }
    const handleActionClick = () => {
        if(isValid){
            setIsActiveLoading(true)
            if(values.hasOwnProperty("id") && values.id !== undefined  && values.id !== ""){
                PlanService.updatePlan(values.id, values)
                .then((data)=>{
                    setIsActiveLoading(false)
                  toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                  window.location.reload();
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                });
            } else{
                PlanService.insertPlan(values)
                .then((data)=>{
                    setIsActiveLoading(false)
                  toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                  window.location.reload();
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                });
            }
        }

    }
    const onClickItem = (event) =>{
        console.log(event.target)
        let actionType = event.target.getAttribute("data-type")
        selectedId = event.target.getAttribute("data-row")
        loadDataRow(actionType)
    }

    ////////////////////////////////////////////////////   -> Render
    const renderDialog = () => {
        return (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"warning"} sx={{"paddingRight":2}} >warning</Icon>{"Delete|Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SoftButton onClick={handleCloseDialog} >Disagree</SoftButton>
              <SoftButton onClick={handleAgreeDialog}  size={"large"}  color={"info"}>Agree</SoftButton>
            </DialogActions>
          </Dialog>
        )
    }


    const renderModal = () => {
        return (
         <Modal open={open}>
            <Fade in={open}>
              <Card sx={ModalStyleHalf}>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box p={2} mb={2}>
                        <Typography variant="h5">{modalTitle} Plan</Typography>
                      </Box>
                      <Box component="form" pb={1} px={1}>
                          <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"} color={"text"} fontWeight={"bold"}>Name (*)</SoftTypography>
                                <TextField
                                      fullWidth
                                      required
                                      error={!!errors.name}
                                      helperText={errors.name?.message}
                                      value={values.name}
                                      {...register("name")}
                                      onChange={(event) => {
                                        handleChange({ ...values, name: event.target.value })
                                      }}
                                      InputProps={{ autoComplete: "" }}
                                      placeholder="Glass Cleaner"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"} color={"text"} fontWeight={"bold"}>Price (*)</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.price}
                                      helperText={errors.price?.message}
                                      value={values.price}
                                      type={"number"}
                                      autoComplete={""}
                                      {...register("price")}
                                      onChange={(event) => {
                                        handleChange({ ...values, price: event.target.value })
                                      }}
                                      placeholder="200 $"
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"} color={"text"}
                                                 fontWeight={"bold"}>Total Days</SoftTypography>
                                <SoftSelect
                                    defaultValue={{
                                        value: values.total_day_count,
                                        label: values.total_day_count === 0 ? "Without Trial" : values.total_day_count + " Days"
                                    }}
                                    onChange={(e)=>handleChange({ ...values, total_day_count: e.value })}
                                    size={"medium"}
                                      options={[
                                        { value: "7", label: "7 Days" },
                                        { value: "30", label: "30 Days" },
                                        { value: "365", label: "365 Days" },
                                      ]}
                                />

                              </Grid>
                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"} color={"text"}
                                                 fontWeight={"bold"}>Trial Days</SoftTypography>
                                <SoftSelect
                                    defaultValue={{
                                        value: values.trial_day_count,
                                        label: values.trial_day_count === 0 ? "Without Trial" : values.trial_day_count + " Days"
                                    }}
                                    onChange={(e)=>handleChange({ ...values, trial_day_count: e.value })}
                                    size={"medium"}
                                    // defaultValue={{ value: "30", label: "30 Days" }}
                                      options={[
                                        { value: "0", label: "Without Trial" },
                                        { value: "3", label: "3 Days" },
                                        { value: "7", label: "7 Days" },
                                        { value: "30", label: "30 Days" },
                                        { value: "365", label: "365 Days" },
                                      ]}
                                />

                              </Grid>
                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"}
                                                 color={"text"} fontWeight={"bold"}>Process Limit / Day</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.process_limit_per_day}
                                      helperText={errors.process_limit_per_day?.message}
                                      value={values.process_limit_per_day}
                                      type={"number"}
                                      autoComplete={""}
                                      {...register("process_limit_per_day")}
                                      onChange={(event) => {
                                        handleChange({ ...values, process_limit_per_day: event.target.value })
                                      }}
                                      placeholder="10"
                                    />

                              </Grid>
                              <Grid item xs={12} md={6}>
                                <SoftTypography  sx={{"marginLeft":"5px"}} variant={"subtitle1"}
                                                 color={"text"} fontWeight={"bold"}>Record Limit / Process</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.record_limit_per_process}
                                      helperText={errors.record_limit_per_process?.message}
                                      value={values.record_limit_per_process}
                                      type={"number"}
                                      autoComplete={""}
                                      {...register("record_limit_per_process")}
                                      onChange={(event) => {
                                        handleChange({ ...values, record_limit_per_process: event.target.value })
                                      }}
                                      placeholder="3000"
                                    />

                              </Grid>
                              <Grid item xs={12} md={12} ml={1} mt={2}>
                                <SoftBox display="flex" alignItems="center">
                                  <Checkbox defaultChecked={values.has_adviser_comment}
                                            onChange={(e,checked) =>
                                                handleChange({ ...values, has_adviser_comment: checked })} />
                                  <SoftBox ml={1}>
                                    <SoftTypography variant="h6" fontWeight="medium" color="text">
                                      Has Adviser Comment
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Grid>

                              <Grid item xs={12} md={4} mt={3} >
                                <SoftButton disabled={!isValid} color={"success"} size={"large"} onClick={e => handleActionClick()} >Save Data</SoftButton>
                              </Grid>
                              <Grid item xs={12} md={4} mt={3}>
                                <SoftButton color={"dark"} size={"large"} onClick={handleCloseModal} >Close</SoftButton>
                              </Grid>
                        </Grid>
                      </Box>
                  </Card>
              </Card>
            </Fade>
          </Modal>
        )
    }

    ////////////////////////////////////////////////////   -> Data Loader
    const loadDataRow = (type) => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== ""){
            setIsActiveLoading(true)
            PlanService.getPlanItem(selectedId)
                .then((data)=>{
                    setIsActiveLoading(false)
                    console.log(data.data.data)
                    setValues(data.data.data);
                    if(type === "edit"){
                        handleOpenModal()
                    }else{
                        handleOpenDialog()
                    }
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 2000,
                      hideProgressBar: false,
                    });
                });
        }
    }

    return (
        <LoadingOverlay active={isActiveLoading} spinner text='Loading your content...'>
            <DashboardLayout>
          <DashboardNavbar />
          {renderDialog()}
          {renderModal()}
          <SoftBox pt={6} pb={3}>
            <SoftBox mb={3}>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    All Plans
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    List of active plans.
                  </SoftTypography>
                </SoftBox>
                <Stack spacing={1} direction="row">
                  <SoftButton variant="gradient" color="primary" size="small" onClick={handleOpenModal}>
                      + new plan
                  </SoftButton>
                </Stack>
              </SoftBox>
                <DataTable
                    table={loadPlanData(onClickItem)}
                    canSearch
                    isSorted={true}
                    entriesPerPage={{
                      defaultValue: 50,
                      entries: [10, 20, 50],
                    }} />
              </Card>
            </SoftBox>
          </SoftBox>
          <Footer />
        </DashboardLayout>
        </LoadingOverlay>
    );
}

export default Plans;
