
// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({onClick, rowId}) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip onClick={onClick} data-row={rowId} data-type={"edit"} title="Edit Item" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip onClick={onClick} data-row={rowId} data-type={"delete"} title="Delete Item" placement="top">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}
export function ActionCellDisable({onClick, rowId}) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mx={2}>
        {/*<SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>*/}
        {/*  <Tooltip onClick={onClick} data-row={rowId} data-type={"edit"} title="Edit Item" placement="top">*/}
        {/*    <Icon>edit</Icon>*/}
        {/*  </Tooltip>*/}
        {/*</SoftTypography>*/}
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip onClick={onClick} data-row={rowId} data-type={"delete"} title="Disable Item" placement="top">
          <Icon>error</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}
export function ActionCellWithDownload({onClick, rowId, status}) {
  return (
    <SoftBox display="flex" alignItems="center">

        <SoftBox mx={1}>
            {(status === "finished" || status === "blocked") && <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip onClick={onClick} data-row={rowId} data-type={"download"} title="Download Data" placement="top">
              <Icon>download</Icon>
            </Tooltip>
          </SoftTypography>}
        </SoftBox>
        <SoftBox mx={1} >
          {status === "blocked" && <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip onClick={onClick} data-row={rowId} data-type={"rerun"} title="Re Run" placement="top">
              <Icon>refresh</Icon>
            </Tooltip>
          </SoftTypography>}
        </SoftBox>
        <SoftBox mx={1}>
          {status === "finished" && <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip onClick={onClick} data-row={rowId} data-type={"report"} title="Assessment" placement="top">
              <Icon>assessment</Icon>
            </Tooltip>
          </SoftTypography>}
        </SoftBox>
        <SoftBox mx={1} sx={{"display":"none"}} >
          <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip onClick={onClick} data-row={rowId} data-type={"cancel"} title="Cancel" placement="top">
              <Icon>cancel</Icon>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
        <SoftBox mx={1} sx={{"display":"none"}}>
          <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
            <Tooltip onClick={onClick} data-row={rowId} data-type={"delete"} title="Delete Item" placement="top">
              <Icon>delete</Icon>
            </Tooltip>
          </SoftTypography>
        </SoftBox>
    </SoftBox>
  );
}

export default ActionCell;
