import axios from "axios";

import  { getPublicURL} from "./core.service";
export const getUser = (token) => axios.get(`${getPublicURL()}getUser?activation=` + token);

export const login = (email, password) => {
  // logout();
  const raw = JSON.stringify({password, email});

  return axios.post(`${getPublicURL()}login`, raw, {
    headers:{
      "Authorization":"",
    }
  });
};
// export const logout = (email, password) => {
//   logout();
//   const raw = JSON.stringify({password, email});
//   return axios.post(`${getPublicURL()}login`, raw);
// };
export const loginAfterProcess = (email, password, process) => {
  // logout();
  return axios.post(`${getPublicURL()}login`, {password, email, process},{headers:{"Authorization":"",}});
};
export const register = (data) => {
  return axios.post(`${getPublicURL()}register`, data, {headers:{"Authorization":"",}});
};
export const confirmAccount = (data) => {
  return axios.post(`${getPublicURL()}active`, data, {headers:{"Authorization":"",}});
};

export const logout = () => {

    eraseCookie();
    localStorage.clear();
    axios.post(`${getPublicURL()}logout`, null, {
      headers:{"Authorization": localStorage.getItem("access_token")}
    }).then((data=>{
      console.log(data);
    })).catch((e=>{
      console.log("error in logout")
    }));
};

export const getDeviceId = () => {
  let device_id = localStorage.getItem("device_id")
  if (device_id !== undefined && device_id !== null && device_id !== ""){
    return device_id
  }
  return  "";
}
export const setDeviceId = (device_id) => {
  if (device_id !== null && device_id !== undefined && device_id !== ""){
    localStorage.setItem("device_id", device_id);
  }
}

export const getUserIsLogin = () => {
  return localStorage.getItem("access_token") !== undefined &&
      localStorage.getItem("access_token") !== null &&
      localStorage.getItem("access_token").length > -1 ;
};

function eraseCookie() {
  // document.cookie.split(";").forEach(
  //   function(c) {
  //     document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/");
  //   });
  // document.cookie = 'Authorization=; path=/; domain=.rbranalytics.io; expires=' + new Date(0).toUTCString();
  // document.cookie = 'Authorization=; path=/; domain=dashboard.rbranalytics.io; expires=Thu, 01 Jan 2010 00:00:00 UTC;';
  // document.cookie ='Authorization=;  path=/; domain=rbranalytics.io; expires=Thu, 01 Jan 2010 00:00:00 UTC; ';
  localStorage.removeItem("access_token")

}

function setPermission (permission){
  localStorage.setItem("permission", permission)
}

function setToken (token){
  localStorage.setItem("access_token", token)
}

const AuthService = {
  login,
  loginAfterProcess,
  logout,
  register,
  confirmAccount,
  getDeviceId,
  setDeviceId,
  setPermission,
  setToken,
};
export default AuthService;
