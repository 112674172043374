

// ProductsList page components
import ActionCell from "layouts/ecommerce/products/products-list/components/ActionCell";


import {React, useEffect, useState} from "react";
import { getSubscriptionList } from "services/subscription.service"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusCell from "../../ecommerce/orders/order-list/components/StatusCell";


export function loadSubscriptionData(onClickItem){
  const columns = [
    { Header: "Customer", accessor: "customer_name" },
    { Header: "Email", accessor: "customer_email"},
    { Header: "Start", accessor: "start_at"},
    { Header: "Remaining", accessor: "remaining_days" },
    { Header: "Plan", accessor: "plan_name" },
    { Header: "Status", accessor: "status",
      Cell: ({ value }) => {
        let status;
        if (value === "active") {
          status = <StatusCell icon="done" color="success" status="Active" />;
        }  else if (value === "canceled") {
          status = <StatusCell icon="close" color="error" status={value} />;
        } else {
          status = <StatusCell icon="info" color="warning" status={value} />;
        }
        return status;
      }
    },
    { Header: "action", accessor: "subscription_id" ,
      Cell: ({ value }) => {
        return <ActionCell onClick={onClickItem} rowId={value}  />
      }
    },
  ]
  const [rows, setRows] = useState([])
  function requestServer(rowsSetter){
    getSubscriptionList()
      .then((data)=>{
        rowsSetter(data.data.data)
      }).catch((e)=>{
        toast.error(e.response.data.status, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  }
  useEffect(()=>{ requestServer(setRows) }, [setRows]);
  return {
    columns : columns,
    rows: rows
  }
}