
import React, {useEffect, useState} from 'react'
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsHeatmap from "highcharts/modules/heatmap";

import HighchartsReact from 'highcharts-react-official'
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useLocation } from "react-router-dom";
import ProcessService from "../../../services/process.service";
import {toast} from "react-toastify";
import Grid from "@mui/material/Grid";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import { useSoftUIController, setMiniSidenav } from "context";
import { jsPDF } from "jspdf";

import html2canvas from 'html2canvas'

function ReportViewer() {

    const search = useLocation().search;
    const [controller, dispatch] = useSoftUIController();
    HighchartsHeatmap(Highcharts);
    const [recordCount, setRecordCount] = useState(10)
    const [failedYear, setFailedYear] = useState(2010)
    const [onlyData , setOnlyData] = useState();
    const [years, setYears] = useState([])
    const [mergeYears, setMergeYears] = useState({})
    const [heatMaps, setHeatMaps] = useState([])
    const [inspectionYear, setInspectionYear] = useState({})
    const [failures, setFailures] = useState([])
    const option_failures = {
            title: { "text": "Failure Projection" },
            chart:{ "type":"column" } ,
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none'
                    }
                }
            },
            loading: {
                labelStyle: {
                    top: '35%',
                    fontSize: "2em"
                }
            },


            legend: {
                padding: 0,
                margin: 5
            },
            series: [ { data: failures, "name":"Failures" }],
            xAxis: {
                categories: years,
                crosshair: true,
                labels: {
                    format: '{value}'
                }
            },
            yAxis:{
                min: 0,
                title: {
                    text: 'Failure Frequency'
                }
            }
        };
    const option_analytics = {
            title: { "text": "Asset Health Predictive Analytics" },
            chart: {
                type: 'heatmap',
                marginTop: 40,
                marginBottom: 80,
                plotBorderWidth: 0
            },

            series: [{
                name: 'Heat Maps',
                data: heatMaps,
                // borderWidth: "0.4px",
                // borderColor: "#ffffff",
                // accessibility: { enabled: false },
                // tooltip: {
                //     headerFormat: 'Predict<br/>',
                //     pointFormat: '<b>{point.value}</b>'
                // },
                // states : {
                //     hover : {
                //         color : '#BADA55'
                //     }
                // },
            }],
            colors:["#008000"],
            colorAxis : {
                min:-1,
				dataClassColor : 'category',
				dataClasses : [{
						to : 5,
                        name: 'AH5 (within 5 years)',
                        color:"#ff0000",
					},
                    {
						from :5,
						to : 10,
                        name: "AH4 (within 10 years)",
                        color:"#ffa500",
					},
                    {
						from :10,
						to : 25,
                        name: "AH3 (within 25 years)",
                        color:"#ffff00",
					},
                    {
						from :25,
						to : 40,
                        name: "AH2 (within 40 years)",
                        color:"#adff2f",
					},
                    {
						from : 40,
                        name: 'AH1 (within greater than 40 years)',
                        color:"#008000"
					}
				],
			},
            // legend: {
            //   align: "bottom",
            //   layout: "vertical",
            //   // margin: 0,
            //   // verticalAlign: "top",
            //   // y: 25,
            //   // symbolHeight: 280
            // },
            xAxis: {
                categories: years,
                crosshair: true,
            },
            yAxis:{
                title: {
                    text: 'Segment Number'
                }
            },

            legend: {
                padding: -15
            },


        };
    const option_mergers = {
            title: { "text": 'Asset Age Distribution' },
            chart:{ "type":"column" } ,
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [  { data: Object.values(mergeYears) , name: "Count"}],
            xAxis: {
                categories:  Object.keys(mergeYears) ,
                crosshair: true,
                labels: {
                    format: '{value} Years'
                }

            },
            yAxis:{
                min: 0,
                title: {
                    text: 'Number of Components'
                }
            }
        };
    const option_line = {
            title: { "text": 'RBI Predictive Analytics' },
            plotOptions: {
                // column: {
                //     pointPadding: 0.2,
                //     borderWidth: 0
                // }
            },
            series: [
                // { data: Object.values(inspectionYear) , name: "Do Nothing"} ,
                {
                    data: Object.values(inspectionYear) ,
                    name: "Inspection Powered",
                    threshold: 1,
                    // zones: [{
                    //     value: 1,
                    //     fillColor: 'green',
                    //     color: 'green'
                    // },{
                    //     value: 4,
                    //     fillColor: 'orange',
                    //     color: 'red'
                    // }],


                }
            ],
            xAxis: {
                categories:   years ,
                crosshair: true,

            },
            yAxis: {
                label:   "years" ,
                name:   "years" ,
                title: {
                    text: 'Failure Frequency'
                }
            },

        };
    const loadData = (id) =>{
        ProcessService.getProcessItem(id)
        .then((data) => {
        // console.log(data.data.data)
        let outputUrl = data.data.data["output_report_path"]
        let onlyUrl = data.data.data["only_report_path"]
        let raw_data = data.data.data["raw_data_file_path"]

        // fetch(onlyUrl, {referrerPolicy: "unsafe-url" }).then((res)=>{
        //     res.blob().then((blb)=>{
        //         // console.log(blb)
        //         setOnlyData(blb)
        //     })
        // })
        fetch(onlyUrl)
            .then((res)=>{
            res.text().then((responseText) => {

                let years = []
                let mergeYears = {}
                let failures = []
                let pof_years = {}
                let rowId = 0;
                let heatMaps = [];
                let yearKey = 0
                let startYearKey = 0
                let headerRows = responseText.split(/\n/)[0].split(",")
                for (let j = 0; j < headerRows.length; j++) {
                    let modifyValue = headerRows[j].trim().toLowerCase()
                    if(modifyValue.includes("install") && modifyValue.includes("year") ){
                        yearKey = j;
                    } else if(modifyValue.includes("2010")){
                        startYearKey = j;
                        break
                    }
                }
                let endYearKey = startYearKey + 50;
                let currentYear = parseInt(new Date().getFullYear())
                // console.log(headerRows, yearKey, startYearKey, endYearKey)
                responseText.split(/\n/).slice(1).forEach(element => {

                    const row = element.split(',')
                    if(row.length < 2){
                        return;
                    }
                    const year = parseInt(row[yearKey]);
                    let colId = 0;
                    let lastRecord = 0
                    for (let i = startYearKey; i < endYearKey; i++) {
                        let floatedVar = parseFloat(row[i])
                        if(!isNaN(row[i])){
                            if (pof_years.hasOwnProperty(i)){
                                pof_years[i] +=  floatedVar
                            } else{
                                pof_years[i] =  floatedVar
                            }
                        }

                        heatMaps.push([colId, rowId, floatedVar === 0 ? 10000 : parseInt(1/floatedVar) ])
                        colId += 1

                    }

                    rowId += 1
                    let found = false
                    if (isNaN(currentYear-year)){
                        return
                    }
                    for (const mKey in mergeYears) {
                       if (parseInt(mKey) === (currentYear-year)){
                           mergeYears[parseInt(mKey)] += 1
                           found = true
                           break
                       }
                    }
                    if (!found){
                       mergeYears[(currentYear-year)] = 1
                    }
                })
                console.log(heatMaps)
                let i = 0
                let idx = 0
                for (const heatMapsKey in pof_years) {
                    if (idx === 0 && pof_years[heatMapsKey] > 1){
                        setFailedYear(currentYear + 1);
                        break
                    }
                    if (i > 0 && pof_years[i] < 1 && pof_years[heatMapsKey] > 1){
                        setFailedYear(2010 + parseInt(idx))
                        break
                    }
                    i = heatMapsKey;
                    idx++;
                }
                setMergeYears(mergeYears)
                setHeatMaps(heatMaps)
                setInspectionYear(pof_years)
                setRecordCount(rowId)
            });
        })
            .catch((e)=>{
                toast.error("error in fetch",{
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                  hideProgressBar: false,});
            })
        fetch( outputUrl)
            .then((response)=>{
            response.text().then((responseText) =>{
                let years = []
                let failures = []
               responseText.split(/\n/).slice(1).forEach(element => {
                    const row = element.split(','), year = row[2], failure = +row[1];
                    years.push(year)
                    if (failure > 0){

                     failures.push(failure)
                    }
                })
                setYears(years)
                setFailures(failures)
            })
        })
            .catch((e)=>{
                toast.error("error in fetch", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                  hideProgressBar: false,});
            })

    })
        .catch((e)=>{
            toast.error(e.response.data.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
              hideProgressBar: false,});
        })
    }
    useEffect(()=>{
        const id = new URLSearchParams(search).get("id");
        // console.log(id); //101
        setTimeout(()=>{loadData(id);}, 4000)
        loadData(id);
    }, [setOnlyData])


    return (<DashboardLayout>
            <DashboardNavbar />
              <SoftBox my={3} id={"screenbar"}>
                <Card>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>

                      <Grid container justifyContent="space-between">

                       {/*<Grid item xs={12} md={12}>*/}
                       {/*    <SoftBox lineHeight={1}>*/}
                       {/*        <SoftTypography variant="h5" fontWeight="medium">*/}
                       {/*         Generated Report*/}
                       {/*       </SoftTypography>*/}
                       {/*     </SoftBox>*/}
                       {/*</Grid>*/}

                        <Grid item xs={12} md={12} padding={"1rem"}>
                            <SoftBox lineHeight={1}>
                               <SoftTypography variant="body" fontWeight="medium">
                                Consultant Notes
                              </SoftTypography>
                               <SoftTypography variant="body2" fontWeight="medium" color={"secondary"}>
                                   This report has been generated based on the corrosion damage mechanism in your
                                   <span style={{"margin":"5px", "fontSize":"1.2rem","color":"darkgreen"}}>{recordCount}</span>
                                    records circuits and according to the best industry solutions. To customize the report,
                                   please contact us at analytics@rbranalytics.io. Here is a list of other services we provide:
                               </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Update RBI report for all your assets including piping and other equipment
                                </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Modify RBI methodology based on other damage mechanisms in various fluid types
                                </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Adjust damage mechanism rates based on your historical data
                                </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Resolve your data gap issues based on the best practices
                                </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Optimize your inspection plans for risk management purposes
                                </SoftTypography>
                                <SoftTypography variant="body2" fontWeight="medium" style={{"margin":"0.5rem"}}>
                                    * Recommend optimized inspection frequencies to ensure compliance
                                </SoftTypography>


                               <SoftTypography variant="body2" fontWeight="medium" style={{"marginTop":"1rem"}}>
                                   RBR Analytics is here to help you with any data analytics and reliability assessments you are looking for.
                                   <br/>
                                   {/*<span style={{"margin":"5px", "fontSize":"3rem","color":"darkgreen"}}>RBR Analytics</span>*/}
                               </SoftTypography>
                               {/*<img src={brand} />*/}
                               {/* <SoftBox component="img" sx={{"float":"left"}} src={brand} alt="RBR-Dashboard" width="3rem" />*/}
                            </SoftBox>
                        </Grid>
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <HighchartsReact*/}
                        {/*        highcharts={Highcharts}*/}
                        {/*        options={option_failures}*/}
                        {/*        allowChartUpdate={true}*/}
                        {/*        immutable={false}*/}

                        {/*    />*/}
                        {/*</Grid>*/}

                        <Grid item xs={4}  md={4} sx={{"marginTop":"5rem"}}>
                            <SoftTypography color={"text"} fontWeight={"bold"} opacity={0.7}>
                                • Asset Age Distribution<br/>
                            </SoftTypography>
                            <SoftTypography fontWeight={"regular"} variant={"caption"}>
                                Figure 1 shows the age distribution of requested assets.
                            </SoftTypography>

                        </Grid>
                        <Grid item xs={8} md={8}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={option_mergers}
                                allowChartUpdate={true}
                                immutable={false} />
                        </Grid>
                        <Grid item xs={4}  md={4} sx={{"marginTop":"5rem"}}>
                            <SoftTypography color={"text"} fontWeight={"bold"} opacity={0.7}>
                                • Asset Health (AH)<br/>
                            </SoftTypography>
                            <SoftTypography fontWeight={"regular"} variant={"caption"}>
                                Figure 2 shows the current and future health of the
                                requested assets. According to the Asset Health
                                analysis, the majority of these assets are currently
                                in AH1 condition, which means that the next
                                expected failure at the asset level would likely occur
                                within greater than 40 years.
                                The condition of these assets will gradually degrade
                                and as shown above, the majority of these assets
                                will move to AH4 condition by the end of {failedYear === 2010 ? "2060" : failedYear} years.
                                Note, the AH analysis is performed at the asset level
                                and does not represent the system failure. For
                                better understanding of the time to failure at the
                                system level, see the RBI graph below.
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={8} md={8} sx={{"marginTop":"5rem"}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={option_analytics}
                                allowChartUpdate={true}
                                immutable={false}

                            />
                        </Grid>

                        <Grid item xs={4}  md={4} sx={{"marginTop":"5rem"}}>
                            <SoftTypography color={"text"} fontWeight={"bold"} opacity={0.7}>
                                • Risk-based Inspection (RBI)<br/>
                            </SoftTypography>
                            <SoftTypography fontWeight={"regular"} variant={"caption"}>
                                Figure 3 shows the expected accumulated failure
                                frequency of requested assets withing a given
                                system. The system is defined based on the type of
                                analysis you selected for this service. For instance,
                                if you selected “Piping”, the system would be
                                defined as piping system. For “Build Your Facility”
                                service, the system is defined as the facility.
                                According to the RBI analysis, the next failure at the
                                system level is expected to occur in {failedYear === 2010 ? "2060" : failedYear}.
                                Therefore, the predictive RBI approach
                                recommends an inspection to be scheduled prior to
                                2025 failure prevention and risk reduction
                                purposes.
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={8} md={8} sx={{"marginTop":"5rem"}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={option_line}
                                allowChartUpdate={true}
                                immutable={false} />
                        </Grid>

                        <Grid item xs={12} md={12} padding={"1rem"}>
                            <SoftBox lineHeight={2} >
                               <SoftTypography variant="h4" fontWeight="medium">
                                Recommendation
                              </SoftTypography>
                               <SoftTypography variant="h6" fontWeight="medium">
                                   According to the Do Nothing scenario of RBI shown above, your requested asset is recommended to be inspected prior to
                                   <span className={"font textLayer"} style={{"fontSize":"1.4rem","color":"red","padding":"4px"}}>{failedYear === 2010 ? "2060" : failedYear}</span>
                                   As RIDS reveals, an inspection in {new Date().getFullYear()} will result in a significant risk reduction in your system.
                              </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={12}>

                            <SoftBox component="img" sx={{"float":"left"}} src={"https://back.rbranalytics.io/media/images/logo_dark.png"} alt="RBR-Dashboard" width="10rem" />
                        </Grid>

                        <Grid item xs={12} md={12} style={{"margin":"1rem"}}>
                           <SoftButton size={"large"} variant={"outlined"} color={"dark"}
                                       onClick={(e)=> {
                                          const element = document.querySelector("#screenbar")
                                          html2canvas(element, { quality: 0.95, backgroundColor:"#FFFFFF", removeContainer:true }).then(canvas =>{
                                                const pdf = new jsPDF();
                                                const imgData = canvas.toDataURL('image/jpeg');
                                                const imgProps= pdf.getImageProperties(canvas);
                                                const pdfWidth = pdf.internal.pageSize.getWidth();
                                                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                                                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
                                                pdf.save("download.pdf");
                                         }).catch((err)=>{
                                             console.log(err);
                                          }) }} ><Icon>print</Icon> Print</SoftButton>
                       </Grid>
                      </Grid>
                    </SoftBox>
                </Card>
              </SoftBox>
              <Footer />
        </DashboardLayout>)
}

export default ReportViewer;
