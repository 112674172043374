
import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React icons
import Settings from "examples/Icons/Settings";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";
import {toast} from "react-toastify";
function PipelineFacility({stepValidator, handleNext}) {

  const [facility, setFacility] = useState(false);
  const [pipeline, setPipeline] = useState(false);

  useEffect((e)=>{
    stepValidator(false)
    if (localStorage.getItem("pf") === "pipeline"){
      handleSetPipeline();
    }else if(localStorage.getItem("pf") === "facility"){
      handleSetFacility();
    }
  }, [setFacility, setPipeline])

  const handleSetPipeline = () => {
    // setFacility(false)
    // setPipeline(!pipeline)
    // localStorage.setItem("pf", "pipeline")
    toast.error("Pipeline simulation is not available online",
        {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});

  };
  const handleSetFacility = (e) =>  {

    setFacility(!facility)
    setPipeline(false)
    localStorage.setItem("pf", "facility")
    stepValidator(true)
    // alert(e)
    if (e !== null && e !== undefined && e.target !== null && e.target !== undefined){
      handleNext()
    }
  };
  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    color:"",

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
      backgroundColor: `#69af35 !important`,
    },
    "&:hover": {
      backgroundColor: `#69af35 !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });
  const customButtonStylesDisabled = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    color:"",
    disabled:"disabled",

    // "&.MuiButton-contained, &.MuiButton-contained:hover": {
    //   boxShadow: "none",
    //   border: `${borderWidth[2]} solid ${transparent.main}`,
    //   backgroundColor: `#69af35 !important`,
    // },
    // "&:hover": {
    //   backgroundColor: `#69af35 !important`,
    //   border: `${borderWidth[2]} solid ${secondary.main} !important`,
    //
    //   "& svg g": {
    //     fill: rgba(dark.main, 0.75),
    //   },
    // },
  });

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            Select Asset Class
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text" >
          This application has been developed to manage assets and condition data associated with facilities. Contact us if you would like to customize the platform for Pipelines and Distribution assets.
         </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3} >
            <SoftBox textAlign="center" opacity={0.3} >
              <SoftButton
                color="secondary"

                variant={pipeline ? "contained" : "outlined"}
                onClick={handleSetPipeline}
                sx={customButtonStylesDisabled}
              >
                <SpaceShip size="24px" color={pipeline ? "white" : "dark"} />
              </SoftButton>
              <SoftTypography variant="h6">Pipeline</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={facility ? "contained" : "outlined"}
                onClick={handleSetFacility}
                sx={customButtonStyles}
              >
                <Cube size="24px" color={facility ? "white" : "dark"} />
              </SoftButton>
              <SoftTypography variant="h6">Facility</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default PipelineFacility;
