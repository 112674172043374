
import SoftBadge from "components/SoftBadge";


import { useEffect, useState,React } from "react";
import { getPlanList } from "services/plan.service"
import { toast } from 'react-toastify';
import StatusCell from "../../ecommerce/orders/order-list/components/StatusCell";
import ActionCell, {ActionCellDisable} from "../../ecommerce/products/products-list/components/ActionCell";


export function loadPlanData(onClickItem){
  const columns = [
    { Header: "Name", accessor: "name"},
    { Header: "Stripe Key", accessor: "stripe_id"},
    { Header: "Amount ($)", accessor: "amount" },
    { Header: "Currency", accessor: "currency" },
    { Header: "Record/Day", accessor: "record_limit_per_process" },
    { Header: "Process/Day", accessor: "process_per_day" },
    { Header: "Total Days", accessor: "total_day_count" },
    { Header: "Trial Days", accessor: "trial_day_count" },
    { Header: "Adviser", accessor: "has_adviser_comment",
      Cell: ({ value }) => {
        if (value) {
          return <StatusCell icon="done" color="success" status="Yes" />;
        }  else {
          return <StatusCell icon="close" color="error" status="No" />;
        }
      }
    },
    { Header: "Status", accessor: "status",
      Cell: ({ value }) => {
        let status;
        if (value === "active") {
          status = <StatusCell icon="done" color="success" status="Active" />;
        }  else {
          status = <StatusCell icon="close" color="error" status="Deactivate" />;
        }
        return status;
      }
    },
    { Header: "action",accessor: "id",
      Cell: ({ value}) => {
        return <ActionCellDisable onClick={onClickItem} rowId={value}  />
      }
    },
  ]

  const [rows, setRows] = useState([])
  function requestServer(rowsSetter){
    
    getPlanList()
      .then((data)=>{
        if (data.data.data == null){
            data.data.data = []
        }
        rowsSetter(data.data.data)
      }).catch((e)=>{
        toast.error(e.response.data.status, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  }
  useEffect(()=>{
    requestServer(setRows)
  }, [setRows]);
  return {
    columns : columns,
    rows: rows
  }
}