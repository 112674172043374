
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";



import { saveAs } from 'file-saver';
// Data
import {loadProcessData} from "../../../applications/data/process_data";
import {useEffect, useState} from "react";
import UserService from "../../../../services/user.service";
import {toast} from "react-toastify";
import ProcessService from "../../../../services/process.service";
import {getUserIsLogin} from "../../../../services/auth.services";
import {getPublicURL} from "../../../../services/core.service";

function Processes() {
    ////////////////////////////////////////////////////  -> Variable
    let selectedId = "";
    let selectedUrl = "";

    const [hasActivePlan, setHasActivePlan] = useState(true);
    useEffect(()=>{
     UserService.getPaymentMethod()
        .then((data)=>{
          setHasActivePlan(data.data.data["has_active_plan"])
        }).catch((e)=>{
          toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
        })
    }, [setHasActivePlan])
    const downloadData = (id) =>{
        // let fileName = "Sample Data - Inspection-Test.csv";
        saveAs(getPublicURL() + "processes/raw/" + id, "raw.csv");
    }
    const onClickItem = (event) =>{
        // console.log(event.target)
        let actionType = event.target.getAttribute("data-type")
        selectedId = event.target.getAttribute("data-row")
        // selectedUrl = event.target.getAttribute("data-url")
        // alert(actionType)
        // if (actionType === "delete"){
        //     if (confirm("do you want to delete this process ?"))
        // }else if (actionType === "cancel"){
        //
        // }else
        // alert(actionType)
        if(actionType === "rerun"){
            if (confirm("do you want to rerun this process")){
                 ProcessService.startSimulate(selectedId).
                    then((data)=>{
                        toast.info("process started", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                        setTimeout( function (){
                            window.location.reload();
                        }, 1500);
                  }).catch((e)=>{
                      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                  });
            }
        }else if(actionType === "download"){
            downloadData(selectedId)
        }else if(actionType === "report"){
            window.location.href = "/applications/report?id=" + selectedId
        }
    }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Processes
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular"  color={"error"} >
                Notice: if you don't have an active subscription, your simulation will remain in "Blocked" status
              </SoftTypography>
            </SoftBox>
              <SoftBox variant={"gradient"} sx={{"display":hasActivePlan ? "none" : "block"}}>
                  <SoftButton size={"large"} color={"dark"}  component={"a"} href={"/dashboards/pricing"} rel="noreferrer">Select Subscription Now</SoftButton>
              </SoftBox>
          </SoftBox>
          <DataTable table={loadProcessData(onClickItem)}
                     entriesPerPage={{defaultValue: 50, entries: [5, 10, 15, 20, 50]}} canSearch isSorted/>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Processes;
