
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";
import ActionCell from "layouts/ecommerce/products/products-list/components/ActionCell";

// Images
import adidasHoodie from "assets/images/ecommerce/adidas-hoodie.jpeg";
import macBookPro from "assets/images/ecommerce/macbook-pro.jpeg";
import metroChair from "assets/images/ecommerce/metro-chair.jpeg";
import alchimiaChair from "assets/images/ecommerce/alchimia-chair.jpeg";
import fendiCoat from "assets/images/ecommerce/fendi-coat.jpeg";
import offWhiteJacket from "assets/images/ecommerce/off-white-jacket.jpeg";
import yohjiYamamoto from "assets/images/ecommerce/yohji-yamamoto.jpeg";
import mcqueenShirt from "assets/images/ecommerce/mcqueen-shirt.jpeg";
import yellowChair from "assets/images/ecommerce/yellow-chair.jpeg";
import heronTshirt from "assets/images/ecommerce/heron-tshirt.jpeg";
import livingChair from "assets/images/ecommerce/living-chair.jpeg";
import orangeSofa from "assets/images/ecommerce/orange-sofa.jpeg";
import burberry from "assets/images/ecommerce/burberry.jpeg";
import dgSkirt from "assets/images/ecommerce/d&g-skirt.jpeg";
import undercover from "assets/images/ecommerce/undercover.jpeg";

// Badges
const removedBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="removed" container />
);
const disabledBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="removed" container />
);
const activeBadge = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Active" container />
);

const dataTableData = {
  columns: [
    {
      Header: "Application",
      accessor: "product",
      width: "20%",
      Cell: ({ value: [name, data] }) => (
        <ProductCell image={data.image} name={name} checked={data.checked}  />
      ),
    },
    { Header: "Category", accessor: "category" },
    { Header: "Install", accessor: "install" },
    { Header: "Rate", accessor: "rate" },
    { Header: "Campaigns", accessor: "campaigns" },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => (value === "active" ? activeBadge : removedBadge),
    },
    { Header: "action", accessor: "action" },
  ],

  rows: [
    {
      product: ["BKLGO Full Zip Hoodie", { image: adidasHoodie, checked: true }],
      category: "Cloting",
      price: "$1,321",
      sku: 243598234,
      install: 0,
      rate: 3.4,
      campaigns: 1,
      status: "removed",
      action: <ActionCell />,
    },
    {
      product: ["MacBook Pro", { image: macBookPro, checked: true }],
      category: "Electronics",
      price: "$1,869",
      sku: 877712,
      install: 0,
      rate: 3.4,
      campaigns: 1,
      status: "active",
      action: <ActionCell />,
    },
    {
      product: ["Metro Bar Stool", { image: metroChair, checked: false }],
      category: "Furniture",
      price: "$99",
      sku: "0134729",
      install: 978,
      rate: 3.4,
      campaigns: 1,
      status: "active",
      action: <ActionCell />,
    }
  ],
};

export default dataTableData;
