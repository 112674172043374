import React, {useEffect, useState} from 'react';

import {Modal, TextField} from "@mui/material";
import Fade from "@mui/material/Fade";
import Card from "@mui/material/Card";
import {ModalStyleHalf} from "../../layouts/modalStyle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CheckoutForm from "./CheckoutForm";

export default function CheckoutFormGenerator({show,product}) {

    return (
         <Modal open={show}>
            <Fade in={show}>
              <Card sx={ModalStyleHalf}>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box p={2} mb={2}>
                        <Typography variant="h5">Add Payment Method</Typography>
                      </Box>
                      <Box component="form" pb={1} px={1}>
                          <Grid container spacing={1}>

                              <Grid item xs={12} md={12}>
                                  <CheckoutForm productId={product}  />
                              </Grid>
                        </Grid>
                      </Box>
                  </Card>
              </Card>
            </Fade>
          </Modal>
        )
}
