
// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for the SidenavCard
import { card, cardContent, cardIconBox, cardIcon } from "examples/Sidenav/styles/sidenavCard";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";
import UserService from "../../services/user.service";

function SidenavCard() {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  // return (<></>)
  return (

      <SoftButton style={{"display": UserService.hasActivePlan() ? "blocked" : "none"}} component={Link} href="/applications/new_process"
                     variant={"contained"} target="_blank" rel="noreferrer" size="large" color={"success"}  fullWidth>
            Start New Process
          </SoftButton>
    // <Card sx={(theme) => card(theme, { miniSidenav })}>
    //   <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
    //
    //     <SoftBox lineHeight={1}>
    //       <SoftBox mb={1.825} mt={-1}>
    //
    //       </SoftBox>
    //
    //     </SoftBox>
    //   </CardContent>
    // </Card>
  );
}

export default SidenavCard;
