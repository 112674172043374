import ActionCell from "./../../../../ecommerce/products/products-list/components/ActionCell";

import Papa from "papaparse";
import {useEffect, useState} from "react";
import "./tabale.css"
import DataTable from "../../../../../examples/Tables/DataTable";
import * as XLSX from "xlsx";
import FullFeaturedCrudGrid from "./table";
import DataGridProDemo from "./table";
import {GridValueSetterParams} from "@mui/x-data-grid";
import {GridCellParams} from "@mui/x-data-grid-pro";
import {GridPreProcessEditCellProps} from "@mui/x-data-grid";
import {GridRenderEditCellParams} from "@mui/x-data-grid";
import CoreService from "../../../../../services/core.service";
import SoftButton from "../../../../../components/SoftButton";
import Icon from "@mui/material/Icon";


function LoadCSV({file, onCompleted, setErrorCount}){

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    // const [validation, setValidation] = useState([]);


    let validation_dict = {
    "facility name":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":[]
    },
    "component":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":["Piping", "Equipment"]
    },
    "sub component":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":[
            {
              "key":"Component",
              "equal":"Piping",
              "values_to_check":["pipe"]
            },
            {
              "key":"Component",
              "equal":"Equipment",
              "values_to_check":["Heat Exchanger", "Pressure Vessel", "Pump",  "Regulator", "Tank", "Valve", "Filter", "Pipe", "Battery", "Boiler", "Furnace", "Inverter", "Rectifier", "Other"]
            },
            {
              "key":"Component",
              "equal":"BYF",
              "values_to_check":[]
            },
        ]
    },
    "segment number":{
        "type":"integer",
        "default":"",
        "nullable":false,
        "min":-999999,
        "max":9999999,
        "values":[]
    },
    "segment description":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":[
            {
                "key":"Sub-Component",
                "equal":"Pressure Vessel",
                "values_to_check":["Head", "Nozzle", "Shell"]
            },
            {
              "key":"Sub Component",
              "equal":"*",
              "values_to_check":[]
            },
        ]
    },
    "material *":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":["Carbon Steel", "Plastic", "Stainless Steel"]
    },
    "install year *":{
        "type":"integer",
        "default":"",
        "nullable":false,
        "min":1800,
        "max":999999999,
        "values":[]
    },
    "operating temperature (c)":{
        "type":"integer",
        "default":"Unknown",
        "nullable":true,
        "min":-40,
        "max":999999999,
        "values":[]
    },
    "operating pressure (kpa) *":{
        "type":"integer",
        "default":"",
        "nullable":false,
        "min":1,
        "max":34000,
        "values":[]
    },
    "maximum operating pressure (kpa)":{
        "type":"integer",
        "default":"Unknown",
        "nullable":true,
        "min":1,
        "max":34000,
        "values":[]
    },
    "size (in) *":{
        "type":"float",
        "default":"",
        "nullable":false,
        "min":0.01,
        "max":99999999,
        "values":[]
    },
    "wall thickness (mm)":{
        "type":"float",
        "default":"Unknown",
        "nullable":true,
        "min":0.01,
        "max":99999999,
        "values":[]
    },
    "material grade":{
        "type":"string",
        "default":"Unknown",
        "nullable":true,
        "min":0.01,
        "max":99999999,
        "values":[
        {
          "key":"Material *",
          "equal":"Carbon Steel",
          "values_to_check":[
              "CSA Z245.1 - 172", "CSA Z245.1 - 207", "CSA Z245.1 - 241", "CSA Z245.1 - 290", "CSA Z245.1 - 317",
              "CSA Z245.1 - 359", "CSA Z245.1 - 386", "CSA Z245.1 - 414", "CSA Z245.1 - 448", "CSA Z245.1 - 483",
              "CSA Z245.1 - 550", "CSA Z245.1 - 620", "CSA Z245.1 - 690", "CSA Z245.1 - 825", "API 5L A25", "API 5L A",
              "API 5L B", "API 5L X42", "API 5L X46", "API 5L X52", "API 5L X56", "API 5L X60", "API 5L X65", "API 5L X70",
              "ASTM A283-C", "ASTM A106-B", "Unknown", "SA-53 GR.E/B", "SA-106-B", "SA-333-GR6", "SA-234-WPB","SA-515-70",
              "SA-516-70", "SA-516-70N", "SA-414 GR.G", "SA-283-C"
          ]
        },
        {
          "key":"Material *",
          "equal":"Plastic",
          "values_to_check":["PE2708", "Unknown"]
        },
        {
          "key":"Material *",
          "equal":"Stainless Steel",
          "values_to_check":["SS304", "SS314", "SS316", "SS405", "SS4010", "SS316", "Unknown"]
        },
    ]
    },
    "yield strength (mpa)":{
        "type":"int",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":9999999,
        "values":[]
    },
    "tensile strength (mpa)":{
        "type":"int",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":9999999,
        "values":[]
    },
    "fluid type *":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":["Natural Gas", "H2 Blended Natural Gas", "Biogas", "Crude Oil", "Water", "Glycol"]
    },
    "above grade or below grade *":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":["AG", "BG"]
    },
    "insulation *":{
        "type":"string",
        "default":"",
        "nullable":false,
        "min":0,
        "max":0,
        "values":["Yes", "No", "Unknown"]
    },
    "insulation type":{
        "type":"string",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":0,
        "values":["Foamglass", "Pearlite", "Fiberglass", "Mineral Wool", "Calcium Silicate", "Asbestos", "Unknown", "Other"]
    },
    "insulation condition":{
        "type":"string",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":0,
        "values":["No Insulation", "Above Average", "Average", "Below Average", "Unknown"]
    },
    "severity of vibration":{
        "type":"string",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":0,
        "values":["Minor", "Moderate", "Severe", "Unknown"]
    },
    "corrosion driving force":{
        "type":"string",
        "default":"Unknown",
        "nullable":true,
        "min":0,
        "max":0,
        "values":["Marine/Cooling Tower Drift Area", "Temperature", "Arid/Dry", "Severe", "Unknown"]
    },
    "external corrosion rate (mm/yr)":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":9999999,
    "values":[]
    },
    "internal corrosion rate (mm/yr)":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":9999999,
    "values":[]
    },
    "coating type":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Fusion Bonded Epoxy", "Liquid Epoxy", "Asphalt Enamel", "Asphalt Mastic", "Coal Tar Enamel",
      "Extruded Polyethylene with Mastic or Rubber", "Mill Applied PE Tape with Mastic",
      "Field Applied PE Tape with Mastic", "Three-Layer PE or PP", "Unknown", "No Coating"]
    },
    "coating install year":{
    "type":"integer",
    "default":"Unknown",
    "nullable":true,
    "min":1800,
    "max":9999999,
    "values":[]
    },
    "coating quality":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["None or Poor", "Medium", "High", "Unknown"]
    },
    "coating maintenance":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "coating exceeding maximum operating temperature":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "complexity":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Below Average", "Average", "Above Average", "Unknown"]
    },
    "design allowing water pool":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "soil to air interface":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "primary soil type":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Sand", "Silt", "Clay", "Silt-Clay Mixture", "Unknown"]
    },
    "soil resistivity":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Very Corrosive", "Corrosive", "Moderately Corrosive", "Mildly Corrosive", "Progressively Less Corrosive", "Unknown"]
    },
    "cathodic protection effectiveness":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["No CP on structure and CP on an adjacent Structure could cause stray current",
      "No cathodic protection", "CP exists but is not tested annually", "CP exists and is tested annually", "Unknown"]
    },
    "nde inspected":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "inspection year":{
    "type":"integer",
    "default":"Unknown",
    "nullable":true,
    "min":1800,
    "max":9999999,
    "values":[]
    },
    "data confidence rating":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Low Confidence", "Medium Confidence", "High Confidence", "Unknown"]
    },
    "measured thickness (mm)":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":0.01,
    "max":999999,
    "values":[]
    },
    "repair strategy":{
    "type":"string",
    "default":"Unknown",
    "nullable":false,
    "min":0,
    "max":0,
    "values":["Repairable", "Non-Repairable"]
    },
    "slope Parameter (shape/beta)":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":1,
    "max":4,
    "values":[]
    },
    "carachteristic life parameter (scale/eta)":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":0.01,
    "max":999999,
    "values":[]
    },
    "life expectency (years) *":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":0.01,
    "max":999999,
    "values":[]
    }, // subcomponent not other required
    "failure multiplier":{
    "type":"float",
    "default":"Unknown",
    "nullable":true,
    "min":1,
    "max":999999,
    "values":[]
    },
    "inspected":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Yes", "No", "Unknown"]
    },
    "inspection result":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Pass", "Fail", "Unknown"]
    },
    "post inspection treatment":{
    "type":"string",
    "default":"Unknown",
    "nullable":true,
    "min":0,
    "max":0,
    "values":["Replaced/Fully Refurbished", "Replaced/Partially Refurbished", "Unknown", "None"]
    },
    }
    const checkValidation = (col, row) => {
    let key = col.trim().toLowerCase().replace("-"," ")
    let value = row[col]
    if(value === undefined || value === null ){
        value = ""
    }
    value = value.toString().toLowerCase().trim()
    let validation = validation_dict[key]
    // v_row[rowKey] = true
    if(validation !== null && validation !== undefined){
        let defaultValue =  validation.default.toString().toLowerCase().trim()


        // -------------------------------------------------------- Check Default
        if (value === defaultValue){
            return true
        }

        // -------------------------------------------------------- Check Null
        if (!validation.nullable && value.length === 0){
          return  false
        }

        // ----------------------------------------------------------- Check Min And Max
        if (validation.type === "integer") {
            let intValue = parseInt(value)
            if (isNaN(intValue) || validation.min > intValue || validation.max < intValue){
                return  false
            }
        }

        if (validation.type === "float" ) {
            let floatValue = parseFloat(value)
            if (isNaN(floatValue) || validation.min > floatValue || validation.max < floatValue){
                return  false
            }
        }

        // -------------------------------------------------------- Check Values

        if (validation.values.length > 0  ){
            if (typeof validation.values[0] === "string"){
                for (const validationElement of validation.values) {

                    if( value === validationElement.toString().toLowerCase().trim()){
                        return true
                    }
                }
            }



            let found = false
            if (typeof validation.values[0] === "object"){
                for (const validationElement of validation.values) {
                    if(row[validationElement["key"]] === validationElement["equal"].toString().trim().toLowerCase()){
                        validation.values = validationElement["values_to_check"]
                        found = true
                        break
                    }
                    if(validationElement["equal"] === "*"){
                        return true
                    }
                }
            }
            if (!found){
                return true
            }
            found = false;
            for (const validationKey in validation.values) {
                let xxx = validation.values[validationKey].toString().trim().toLowerCase()
                  if( xxx === value){
                    found = true
                    break
                  }
            }
            return  found
        }

    }
    return true
    }


    const getColumnEditable = (col) =>{
        let buildYourFacility = CoreService.getProcessType().toLowerCase().includes("build")
        const columnEditable = {
          "Component": buildYourFacility ? true : false,
          "Sub-component": buildYourFacility ? true: false,
          "Segment Description": true,
          "Segment Number": false,
        }
        if (columnEditable && columnEditable.hasOwnProperty(col)){
            return columnEditable[col];
        }
        return true
    }
    const getColumnWidth = (col) =>{
        const columnWidth = {
          "Component": 140,
          "Sub-component": 140,
          "Segment Description": 180,
          "Segment Number": 140,
        }
        if (columnWidth && columnWidth.hasOwnProperty(col)){
            return columnWidth[col];
        }
        return 120
    }
    const getColumnType = (col) =>{

        let buildYourFacility = CoreService.getProcessType().toLowerCase().includes("build")
        const columnType = {
          "Component": "singleSelect",
          "Sub-component": "singleSelect",
          "Model":"singleSelect",
           "Repair Strategy":"singleSelect",
          "Segment Number":"number",
          "Segment Description":"singleSelect",
          "Install Year *":"float",
          "Slope Parameter (Shape/Beta)":"float",
          "Carachteristic Life Parameter (Scale/Eta)":"float",
          "Life Expectency (Years) *":"number",
          "Failure Multiplier":"float",
          "Inspected":"singleSelect",
          "Inspection Year":"integer",
          "Inspection Result":"singleSelect",
          "Post Inspection Treatment":"singleSelect",
          // "Segment Description":"singleSelect",
        }
        if (columnType && columnType.hasOwnProperty(col)){
            return columnType[col];
        }
        return "string"
    }
    const getColumnOptions = (col, row) =>{
        let currentComponent = row["Component"];
        // console.log(currentComponent)
        // let type= coreService.getProcessType();
        // alert(CoreService.getProcessType());
        let buildYourFacility = CoreService.getProcessType().toLowerCase().includes("build")
        if (col === "Component" && buildYourFacility){
            return [
                {value: 'Piping', label: 'Piping'},
                {value: 'Equipment', label: 'Equipment'}
            ]
        }
        if (col === "Sub-component" && buildYourFacility){
            if(currentComponent === "Piping") {
                return [ {value: "Pipe", label: "Pipe"} ];
            }else if(currentComponent === "Equipment")
            {
               return [
                    {value: "Heat Exchanger", label: "Heat Exchanger"},
                      {value: "Pressure Vessel", label: "Pressure Vessel"},
                      {value: "Pump", label: "Pump"},
                      {value: "Regulator", label: "Regulator"},
                      {value: "Control Valve", label: "Control Valve"},
                      {value: "Tank", label: "Tank"},
                      {value: "Valve", label: "Valve"},
                      {value: "Filter", label: "Filter"},
                    ];
            }
        }
        const columnOptions = {
            "Segment Description":[
              {value: "Pipe", label: "Pipe"},
              {value: "Head", label: "Head"},
              {value: "Nozzle", label: "Nozzle"},
              {value: "Shell", label: "Shell"},
              {value: "Bottom Plate", label: "Bottom Plate"},
              {value: "Shell Course", label: "Shell Course"},
              {value: "Outlet Valve", label: "Shell Course"},
            ],

            "Material *":[
              {value: "Carbon Steel", label: "Carbon Steel"},
              {value: "Plastic", label: "Plastic"},
              {value: "Stainless Steel", label: "Stainless Steel"},
              {value: "Aluminum", label: "Aluminum", "for":"Tank"},
              {value: "Other", label: "Other"},
            ],
            "Model":[
              {value: "Boot", label: "Boot"},
              {value: "Non-Boot", label: "Non-Boot"},
              {value: "Control Valve", label: "Control Valve"},
              {value: "Unknown", label: "Unknown"},
            ],
            "Repair Strategy":[
              {value: "Repairable", label: "Repairable"},
              {value: "Non-Repairable", label: "Non-Repairable"}
            ],
            "Inspected":[
              {value: "Yes", label: "Yes"},
              {value: "No", label: "No"},
              {value: "Unknown", label: "Unknown"}
            ],
            "Inspection Result":[
              {value: "Fail", label: "Fail"},
              {value: "Pass", label: "Pass"},
              {value: "Unknown", label: "Unknown"}
            ],
            "Post Inspection Treatment":[
              {value: "Replaced/Fully Refurbished", label: "Replaced/Fully Refurbished"},
              {value: "Replaced/Partially Refurbished", label: "Replaced/Partially Refurbished"},
              {value: "Unknown", label: "Unknown"},
              {value: "None", label: "None"},
            ],
            // "Inspection Year":[
            //     // in inspected : yes : required
            //   {value: "Yes", label: "Yes"},
            //   {value: "No", label: "No"},
            //   {value: "Unknown", label: "Unknown"}
            // ]
        };
        if (columnOptions && columnOptions.hasOwnProperty(col)){
            return columnOptions[col];
        }
        return null
    }
    let errorCount = 0

    useEffect(()=>{
        const reader = new FileReader();
        // reader.rea
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet, {
                blankrows:true,
                defval:"",
                skipHidden:true,
                raw:true
            });
            console.log(parsedData)
            let cols = []
            let valItems = []
            // let validationItems = []
            // cols.push({Header: "Segment Number",  accessor: "id", flex:0,  field: "id", editable: false})
            Object.keys(parsedData[0]).map((key) => {
                // if (key === "Segment Number"){
                //     return
                // }
                cols.push({
                        Header: key,
                        accessor: key,
                        field: key,
                        align: "center",
                        editable: getColumnEditable(key),
                        width: getColumnWidth(key),
                        // valueOptions: getColumnOptions(key),
                        valueOptions: ({ row }) => getColumnOptions(key, row),
                        type: getColumnType(key),
                        // renderEditCell: renderRatingEditInputCell,
                        // valueParser: (value: any, params: GridCellParams) => {
                        //     console.log("valueParser", params)
                        //     return value.toLowerCase();
                        // },
                        // valueSetter: (params: GridCellParams ) => {
                        //   // const [firstName, lastName] = params.value;
                        //   //   console.log("valueSetter", params)
                        //   return params.row;
                        // },
                        // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                        //     // console.log("preProcessEditCellProps", params)
                        //     const hasError = params.props.value.length < 3;
                        //     return { ...params.props, error: hasError };
                        // },

                    });

            });
            let rowId = 0
            parsedData.map((baseRow, baseIndex) => {
                let valItem = {}
                let valItemSpan = {}
                rowId++;
                // valItem["id"] = baseIndex.toString()
                cols.map(data =>{
                  valItem[data["accessor"]] = baseRow[data["accessor"]]
                })
                cols.map(data =>{

                    if (data["accessor"] !== "id"){
                        let isValid = checkValidation(data["accessor"] , valItem)
                        if (!isValid){
                            errorCount++;
                            setErrorCount(errorCount)
                        }
                        valItemSpan[data["accessor"]] = baseRow[data["accessor"]]
                    }else{
                        valItemSpan["id"] = rowId.toString()
                    }
                        // <span style={{"color": isValid ? "black": "red"}}>
                        //     {}
                        // </span>
                })
                // validationItems.push()
                valItems.push(valItemSpan)
            })
            setColumns(cols)
            setRows(valItems)
            // setValidation(validationItems)
          // if (errorCount ===0){
          //   onCompleted(true)
          // }
        };
    }, [file, setRows, setColumns, onCompleted]);

    let data = {"rows": rows, "columns": columns};
    // return (<DataTable table={data} showTotalEntries canSearch={false} />)
    return (
        <>
            <DataGridProDemo data={data} setRows={setRows}  rows={rows} cols={columns} onCompleted={onCompleted} />
        </>
    )
}

export default LoadCSV;
