
import {useEffect, useRef, useState} from "react";

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import ReCAPTCHA from 'react-google-recaptcha';

// Image
import chat from "assets/images/logo_light.svg";
import AuthService from "../../../../services/auth.services";
import {toast} from "react-toastify";

function Illustration() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(()=>{
    AuthService.logout();
  },[])
  const doLogin = (e) =>{
    setLoading(true);
    AuthService.login(email, password).then(
      (data) => {
        // console.log(data)
        localStorage.setItem("access_token", data.data["access_token"]);
        localStorage.setItem("customer", data.data["customer"]);
        AuthService.setPermission(data.data["permission"]);

        toast.success("login successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
        setTimeout(()=>{
          // console.log("login successfully")
          if (data.data["first_login"]){
            window.location.href = "/dashboards/pricing";
          }else{
            window.location.href = "/dashboard";
            // navigate("/dashboard");
          }
        }, 2000)
        setLoading(false);
      },
      (e) => {
        setLoading(false);
        // console.log(e.response.data.status)
           toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});

      }
    );
  }
  const captchaRef = useRef(null);

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: chat,
        title: '',
        description:
          "",
      }}
    >

      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" autoComplete={'new-password'}  aria-autocomplete="both"  onChange={(e) => setEmail(e.target.value)}  />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" onChange={(e) => setPassword(e.target.value)} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>

        {/*<ReCAPTCHA size={"small"} sitekey={"6LeELMImAAAAAAzyCZ3KU3IwRiTMH5lmu_LPmRt7"} ref={captchaRef}  />*/}
        <SoftBox mt={4} mb={1}>
          <SoftButton
              onClick={doLogin}
              variant="gradient" color="info" size="large" fullWidth>
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/register"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
