
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
import {useEffect, useState} from "react";
import Office from "../../../../../examples/Icons/Office";
import Basket from "../../../../../examples/Icons/Basket";
import {toast} from "react-toastify";

function OtherFilters({stepValidator, handleNext}) {
  const [tank, setTank] = useState(false);
  const [pressure, setPressure] = useState(false);
  const [valve, setValve] = useState(false);
  const [regulator, setRegulator] = useState(false);
  const [filter, setFilter] = useState(false);
  const [pump, setPump] = useState(false);

  useEffect((e)=>{
      stepValidator(false)
    if (localStorage.getItem("of") !== undefined && localStorage.getItem("of") !== null){
      handleSet(null, localStorage.getItem("of"));
    }
  }, [])

  const handleSet = (e, value) => {
      if(value === "heat_exchanger"){
        toast.error("this item is Not Available Now", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
        return
      }
      stepValidator(true);
      localStorage.setItem("of", value);
      if (value === "tank"){
        setTank(true);
        setPressure(false);
        setValve(false);
        setRegulator(false);
        setFilter(false);
        setPump(false);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Corrosion"]}))
      }
      if (value === "Pressure Vessle"){
        setTank(false);
        setPressure(true);
        setValve(false);
        setRegulator(false);
        setFilter(false);
        setPump(false);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Corrosion"]}))
      }
      if (value === "valve"){
        setTank(false);
        setPressure(false);
        setValve(true);
        setRegulator(false);
        setFilter(false);
        setPump(false);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Functional", "Leak"]}))
      }
      if (value === "regulator"){
        setTank(false);
        setPressure(false);
        setValve(false);
        setRegulator(true);
        setFilter(false);
        setPump(false);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Functional", "Leak"]}))
      }
      if (value === "filter"){
        setTank(false);
        setPressure(false);
        setValve(false);
        setRegulator(false);
        setFilter(true);
        setPump(false);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Functional"]}))
      }
      if (value === "pump"){
        setTank(false);
        setPressure(false);
        setValve(false);
        setRegulator(false);
        setFilter(false);
        setPump(true);
        localStorage.setItem("of2_items", JSON.stringify({"keys":["Functional"]}))
      }


     if (e !== null && e !== undefined && e.target !== null && e.target !== undefined){
      handleNext()
    }
  };

   const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    ".material-icons-round":{
        fontSize:"3rem !important",
    },
    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
      backgroundColor: `#69af35 !important`,

    },
    "&:hover": {
      backgroundColor: `#69af35 !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });

  const customButtonStylesDisabled = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    color:"",
    disabled:"disabled",
    ".material-icons-round":{
        fontSize:"3rem !important",
    },
    // "&.MuiButton-contained, &.MuiButton-contained:hover": {
    //   boxShadow: "none",
    //   border: `${borderWidth[2]} solid ${transparent.main}`,
    //   backgroundColor: `#69af35 !important`,
    // },
    // "&:hover": {
    //   backgroundColor: `#69af35 !important`,
    //   border: `${borderWidth[2]} solid ${secondary.main} !important`,
    //
    //   "& svg g": {
    //     fill: rgba(dark.main, 0.75),
    //   },
    // },
  });
  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          {/*<SoftTypography variant="h5" fontWeight="regular">*/}
          {/*  Q3.*/}
          {/*</SoftTypography>*/}
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          What kind of equipment do you want to assess ?
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container  justifyContent="center">
          <Grid item xs={12} sm={2}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={tank ? "contained" : "outlined"}
                onClick={e=>handleSet(e, "tank")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"} >propane_tank</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Tank</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={pressure ? "contained" : "outlined"}
                onClick={e=>handleSet(e, "Pressure Vessle")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"} sx={{"fontSize":"12px !important"}}>compress</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Pressure Vessle</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={valve ? "contained" : "outlined"}
                onClick={e=>handleSet(e,"valve")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"}>verified</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Valve</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={regulator ? "contained" : "outlined"}
                onClick={e=>handleSet(e,"regulator")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"}>boy</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Regulator</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2} >
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={filter ? "contained" : "outlined"}
                onClick={e=>handleSet(e,"filter")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"} sx={{fontSize:"2rem!important"}}>grain</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Filter</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            <SoftBox textAlign="center">
              <SoftButton
                color="secondary"
                variant={pump ? "contained" : "outlined"}
                onClick={e=>handleSet(e,"pump")}
                sx={customButtonStyles}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"}>heat_pump</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Pump</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2} sx={{"marginTop":"20px", "display":"none"}}>
            <SoftBox textAlign="center"  opacity={0.3}>
              <SoftButton
                color="secondary"
                variant={"outlined"}
                onClick={e=>handleSet(e,"heat_exchanger")}
                sx={customButtonStylesDisabled}
              >
                <Icon fontSize={"large"} color={"dark"} component={"span"} >grain</Icon>
              </SoftButton>
              <SoftTypography variant="h6">Heat Exchanger</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default OtherFilters;
