
import { useState } from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/logo_light.svg";
import {toast} from "react-toastify";
import AuthService from "../../../../services/auth.services";
function Illustration() {
  const [agreement, setAgreement] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const handleSetAgreement = () => setAgreement(!agreement);
  const navigate = useNavigate();
  // alert()
  const doRegister = () =>{
      if(password === undefined || password.length < 6){
        toast.error("password length must be minimum 6 character or number",
            {position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000, hideProgressBar: false});
        return;
      }
      if(password !== confirmPassword){
        toast.error("Password and Confirmation Password is not equal",
            {position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000, hideProgressBar: false});
        return;
      }
      const data ={
        'password':password,
        'name':name,
        'email':email,
      }

      AuthService.register(data)
          .then((e)=>{
            toast.success("Register Completed check your email please",
            {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
            setTimeout(function () { navigate("/authentication/login"); }, 1000);
          }).catch((e)=>{
            toast.error(e.response.data.status,
                {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
          })
  }

  return (
    <IllustrationLayout
      title="Sign Up"
      description={"Fill in the form to get access to RBR application features"}
      illustration={{
        image: rocket,
        title: "",
        description: ""}}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput placeholder="Email" type={"email"} size="large"  value={email}  onChange={(e) => setEmail(e.target.value)}  />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput placeholder="Name" size="large"  value={name}  onChange={(e) => setName(e.target.value)}  />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large"  value={password} onChange={(e) => setPassword(e.target.value)}  />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Confirm Password" size="large"  value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}  />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={handleSetAgreement} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetAgreement}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;I agree with the&nbsp;
          </SoftTypography>
          <SoftTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            Terms and Conditions
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={doRegister}>
            sign up
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/authentication/sign-in/illustration"
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
