
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Invoice page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";

// Images
import logoCT from "assets/images/logo-ct.png";
import UserService from "../../../../services/user.service";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import Icon from "@mui/material/Icon";

import { useSoftUIController, setMiniSidenav } from "context";
function Invoice() {
  const [controller, dispatch] = useSoftUIController();
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  // const [data,setData] =useState({})
  const [totalPrice, setTotalPrice] = useState(0)
  const [price, setPrice] = useState(0)
  const [tax, setTax] = useState(0)
  const [values, setValues] = useState({})
  useEffect(()=>{
    UserService.loadLastInvoice()
    .then((value)=>{
      // console.log(value)
      // setData(value.data.data)
      setValues(value.data.data)
      if(value.data.data.hasOwnProperty("items") &&
          value.data.data["items"] !== undefined &&
          value.data.data["items"] !== null &&
          value.data.data["items"].length > 0){

            setPrice(Math.round(value.data.data["items"][0]["amount"] / 100).toLocaleString())
            setTax(Math.round((value.data.data["items"][0]["amount"] / 100 * 1.13) - (value.data.data["items"][0]["amount"] / 100)).toLocaleString())
            setTotalPrice(Math.round(value.data.data["items"][0]["amount"]/ 100 * 1.13).toLocaleString())
      }

      // setHasActivePlan(data.data.data["has_active_plan"])
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
    })
  },[setValues, setPrice, setTax, setTotalPrice])
  const RenderInvoice = () => {
      return (<Card>
                  {/* Invoice header */}
                  <SoftBox p={3}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={12} md={4}>
                        <SoftBox component="img" src={logoCT} width="25%" p={1} mb={1} />
                        <SoftTypography variant="h6" fontWeight="medium">
                          147 Denison st, Markham, ON L3R 1B5 CANADA
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                          <SoftTypography display="block" variant="body2" color="secondary">
                            Email: support@rbranalytics.io<br/>
                            tel: +1 (888) 268–5374
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} md={7} lg={3}>
                        <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
                          <SoftBox mt={1}>
                            <SoftTypography variant="h6" fontWeight="medium">
                              Billed to: <br/>
                              {values["customer_name"] }<br/>
                              {values["customer_email"] }
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox mb={1}>
                            <SoftTypography variant="body2" color="secondary">
                              {values["customer_address"]}
                              <br />
                              {/*San Francisco CA*/}
                              {/*<br />*/}
                              {/*California*/}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    </Grid>
                    <SoftBox mt={{ xs: 5, md: 10 }}>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={4}>
                          <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                            Invoice no
                          </SoftTypography>
                          <SoftTypography variant="h5" fontWeight="medium">
                            #{values["invoice_number"]}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={12} md={7} lg={5}>
                          <SoftBox width="100%" display="flex" flexDirection={{ xs: "column", md: "row" }}
                                   alignItems={{ xs: "flex-start", md: "center" }}
                                   textAlign={{ xs: "left", md: "right" }} mt={{ xs: 3, md: 0 }}>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Invoice date:
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" fontWeight="medium">
                                {values["invoice_date"]}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox width="100%" display="flex" flexDirection={{ xs: "column", md: "row" }}
                                   alignItems={{ xs: "flex-start", md: "center" }} textAlign={{ xs: "left", md: "right" }}>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" color="secondary" fontWeight="medium">
                                Due date:
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox width="50%">
                              <SoftTypography variant="h6" fontWeight="medium">
                                {values["due_date"]}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </SoftBox>

                  {/* Invoice table */}
                  <SoftBox p={3}>
                    <SoftBox width="100%" overflow="auto">
                      <Table sx={{ minWidth: "32rem" }}>
                        <SoftBox component="thead">
                          <TableRow>
                            <SoftBox component="th" width={{ xs: "45%", md: "50%" }} py={1.5} px={1} textAlign="left" borderBottom={borderBottom}
                            >
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Item
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox component="th" py={1.5} pl={3} pr={1} textAlign="left" borderBottom={borderBottom}>
                              <SoftTypography variant="h6" color="text" fontWeight="medium">
                                Amount
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          <TableRow>
                            <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                              <SoftTypography variant="body2" color="text">
                                RBR Subscription ({values["items"][0]["item_name"]})
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox component="td" textAlign="left" py={1} pr={1} pl={3} borderBottom={borderBottom}>
                              <SoftTypography variant="body2" color="text">
                                 ${price} USD
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                              <SoftTypography variant="body2" color="text">
                                TAX (%13)
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox component="td" textAlign="left" py={1} pr={1} pl={3} borderBottom={borderBottom}>
                              <SoftTypography variant="body2" color="text">
                                ${tax} USD
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox component="td" textAlign="left" py={1} pr={1} pl={3} borderBottom={borderBottom}>
                              <SoftTypography variant="h5">Total</SoftTypography>
                            </SoftBox>
                            <SoftBox component="td" textAlign="left" py={1} pr={1} pl={3} borderBottom={borderBottom}>
                              <SoftTypography variant="h5">
                                ${totalPrice} USD
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>

                  {/* Invoice footer */}
                  <SoftBox p={3} mt={7}>
                  <Grid container>
                    <Grid item xs={12} lg={5}>
                      <SoftTypography variant="h5" fontWeight="medium">
                        Thank you!
                      </SoftTypography>
                      <SoftBox mt={1} mb={2} lineHeight={0}>
                        <SoftTypography variant="button" fontWeight="regular" color="secondary">
                          If you encounter any issues related to the invoice you can contact us at:
                        </SoftTypography>
                      </SoftBox>
                      <SoftTypography
                        component="span"
                        variant="h6"
                        fontWeight="medium"
                        color="secondary"
                      >
                        email:{" "}
                        <SoftTypography component="span" variant="h6" fontWeight="medium">
                          support@rbranalytics.io
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <SoftBox
                        width="100%"
                        height={{ xs: "auto", md: "100%" }}
                        display="flex"
                        justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        alignItems="flex-end"
                        mt={{ xs: 2, md: 0 }}
                      >
                        <SoftButton
                          variant="gradient"
                          color="info"
                          onClick={() => {

                                // setMiniSidenav(dispatch,true);
                                window.location.href  =values["invoice_url"];
                                // setTimeout(function (){window.print()}, 1000)
                          }}
                        >
                          print
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                </Card>)
  }
  const RenderNotFound = () =>{
    return (<Card>
            <SoftBox p={3}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} >
                  <SoftTypography>
                    <Icon color={"warning"} fontSize={"3rem"} style={{"marginRight":"1rem"}}>error</Icon>No Invoice Found !
                    </SoftTypography>
                </Grid>
              </Grid>
            </SoftBox>
        </Card>)
  }
  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            {values && values.hasOwnProperty("items") ?  <RenderInvoice /> : <RenderNotFound />}
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default Invoice;
