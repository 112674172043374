
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import DataTable from "../../../../examples/Tables/DataTable";
import {Link} from "react-router-dom";
import SoftButton from "../../../../components/SoftButton";
import Stack from "@mui/material/Stack";

import {loadUserData} from "../../../applications/data/user_data";
import {useState} from "react";

import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import LoadingOverlay from 'react-loading-overlay';
// import PlanService from "../../../../services/plan.service";
import {toast} from "react-toastify";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField} from "@mui/material";
import Icon from "@mui/material/Icon";
import Fade from "@mui/material/Fade";
import {ModalStyleHalf} from "../../../modalStyle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UserService from "../../../../services/user.service";
import SoftInput from "../../../../components/SoftInput";
function Users() {


    ////////////////////////////////////////////////////  -> Variable
    let selectedId = "";

    ////////////////////////////////////////////////////  -> Default Value and initializer
    const schema = {
        first_name: Yup.string() .min(2, 'First Name must be at least 4 characters') .required('First Name is required'),
        last_name: Yup.string() .min(2, 'Last Name must be at least 4 characters') .required('First Name is required'),
        email: Yup.string() .email().required('Email is required and must be unique'),
        // price:  Yup.number().positive().min(0, "minimum 0").required("price is required"),
        // day_count:  Yup.number().required("day count is required"),
    }
    const defaultInputValues = {
        first_name: "",
        last_name: "",
        email: "",
    };

    ////////////////////////////////////////////////////  -> React Variable
    const [isActiveLoading,setIsActiveLoading] = useState(false);
    const [values, setValues] = useState(defaultInputValues);
    const validationSchema = Yup.object().shape(schema);
    const { register, handleSubmit, formState: { errors  } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});
    const [isValid, setIsValid] = useState(false);
    const [open, setOpen] = useState(false)
    const [modalTitle, setModalTitle] = useState("Insert");
    const [openDialog, setOpenDialog] = useState(false);

    ////////////////////////////////////////////////////    -> Handler
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleOpenModal = (e) => {
        setOpen(true)
        if(selectedId !== ""){
            setModalTitle("Edit")
        }else{
            setModalTitle("Insert")
        }
    }
    const handleCloseModal = () => {
        setOpen(false)
        setValues(defaultInputValues)
        setIsValid(false);
    }
    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));

    };
    const handleAgreeDialog = () => {
        if(values.hasOwnProperty("id") && values.id !== undefined  && values.id !== ""){
            setIsActiveLoading(true)
            UserService.deleteUser(values.id)
            .then((data)=>{
              setIsActiveLoading(false)
              toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
              window.location.reload();
            }).catch((e)=>{
                setIsActiveLoading(false)
                toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
            });
        }else{
            toast.warning("You don't select any record", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
        }
        handleCloseDialog();
    }
    const handleActionClick = () => {
        if(isValid){
            setIsActiveLoading(true)
            if(values.hasOwnProperty("id") && values.id !== undefined  && values.id !== ""){
                UserService.updateUser(values.id, values)
                .then((data)=>{
                    setIsActiveLoading(false)
                  toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                  window.location.reload();
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                });
            } else{
                UserService.createUser(values)
                .then((data)=>{
                    setIsActiveLoading(false)
                  toast.success("data saved successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                  window.location.reload();
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
                });
            }
            handleCloseModal();
        }

    }
    const onClickItem = (event) =>{
        console.log(event.target)
        let actionType = event.target.getAttribute("data-type")
        selectedId = event.target.getAttribute("data-row")
        loadDataRow(actionType)
    }

    ////////////////////////////////////////////////////   -> Render
    const renderDialog = () => {
        return (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"warning"} sx={{"paddingRight":2}} >warning</Icon>{"Delete|Disable confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure to Delete/Disable this item ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SoftButton onClick={handleCloseDialog} >Disagree</SoftButton>
              <SoftButton onClick={handleAgreeDialog}  size={"large"}  color={"info"}>Agree</SoftButton>
            </DialogActions>
          </Dialog>
        )
    }
    const renderModal = () => {
        return (
         <Modal open={open}>
            <Fade in={open}>
              <Card sx={ModalStyleHalf}>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box p={2} mb={0.1}>
                        <Typography variant="h5">{modalTitle} User</Typography>
                      </Box>
                      <Box component="form" pb={2} px={2}>
                          <Grid container spacing={2}>
                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>Email</SoftTypography>
                                    <SoftInput
                                          fullWidth
                                          required
                                          error={!!errors.email}
                                          helperText={errors.email?.message}
                                          value={values.email}
                                          {...register("email")}
                                          onChange={(event) => {
                                            handleChange({ ...values, email: event.target.value })
                                          }}
                                          InputProps={{ autoComplete: "" }}
                                          placeholder="admin_rbr@gmail.com"
                                    />
                              </Grid>
                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>Password</SoftTypography>
                                    <SoftInput
                                          fullWidth
                                          required
                                          error={!!errors.password}
                                          helperText={errors.password?.message}
                                          value={values.password}
                                          {...register("password")}
                                          onChange={(event) => {
                                            handleChange({ ...values, password: event.target.value })
                                          }}
                                          type={"password"}
                                          InputProps={{ autoComplete: "" }}
                                          placeholder="Password"
                                    />
                              </Grid>
                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>FirstName</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.first_name}
                                      helperText={errors.first_name?.message}
                                      value={values.first_name}
                                      // variant={"outlined"}
                                      {...register("first_name")}
                                      onChange={(event) => {
                                        handleChange({ ...values, first_name: event.target.value })
                                      }}
                                      InputProps={{ autoComplete: "" }}
                                      placeholder="Elham"
                                />
                              </Grid>

                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>LastName</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.last_name}
                                      helperText={errors.last_name?.message}
                                      value={values.last_name}
                                      // variant={"outlined"}
                                      {...register("last_name")}
                                      onChange={(event) => {
                                        handleChange({ ...values, last_name: event.target.value })
                                      }}
                                      InputProps={{ autoComplete: "" }}
                                      placeholder="Barzegar"
                                />
                              </Grid>
                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>Phone</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.phone}
                                      helperText={errors.phone?.message}
                                      value={values.phone}
                                      {...register("phone")}
                                      onChange={(event) => {
                                        handleChange({ ...values, phone: event.target.value })
                                      }}
                                      InputProps={{ autoComplete: "" }}
                                      placeholder="+9(123) 32423"
                                />
                              </Grid>
                              <Grid item xs={12}  md={6}>
                                  <SoftTypography variant={"h6"} fontWeight={"bold"} textGradient={true} color={"text"}>Location</SoftTypography>
                                <SoftInput
                                      fullWidth
                                      required
                                      error={!!errors.location}
                                      helperText={errors.location?.message}
                                      value={values.location}
                                      variant={"outlined"}
                                      {...register("location")}
                                      onChange={(event) => {
                                        handleChange({ ...values, location: event.target.value })
                                      }}
                                      InputProps={{ autoComplete: "" }}
                                      placeholder="Canada"
                                />
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <SoftButton disabled={!isValid} color={"success"} size={"large"} onClick={e => handleActionClick()} >Save Data</SoftButton>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <SoftButton color={"dark"} size={"large"} onClick={handleCloseModal} >Close</SoftButton>
                              </Grid>
                        </Grid>
                      </Box>
                  </Card>
              </Card>
            </Fade>
          </Modal>
        )
    }

    ////////////////////////////////////////////////////   -> Data Loader
    const loadDataRow = (type) => {
        if (selectedId !== undefined && selectedId !== null && selectedId !== ""){
            setIsActiveLoading(true)
            UserService.getUser(selectedId)
                .then((data)=>{
                    setIsActiveLoading(false)
                    console.log(data.data.data)
                    setValues(data.data.data);
                    if(type === "edit"){
                        handleOpenModal()
                    }else{
                        handleOpenDialog()
                    }
                }).catch((e)=>{
                    setIsActiveLoading(false)
                    toast.error(e.response.data.status, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 2000,
                      hideProgressBar: false,
                    });
                });
        }
    }


  return (
        <LoadingOverlay active={isActiveLoading} spinner text='Loading your content...'>
    <DashboardLayout>
      <DashboardNavbar />

          {renderDialog()}
          {renderModal()}
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Members
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                List of all members in system
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              <SoftButton variant="gradient" color="primary" size="small" onClick={handleOpenModal}>
                  + new Member
              </SoftButton>
            </Stack>
          </SoftBox>
          <DataTable
            table={loadUserData("user", onClickItem)}
            entriesPerPage={{
              defaultValue: 50,
              entries: [5, 10, 15, 20, 50],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
        </LoadingOverlay>
  );
}

export default Users;