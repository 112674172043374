import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`;

export const generateToken = (product) => {
  return axios.get(`${getPrivateURL()}subscriptions/create_token/${product}`, {
      headers:{
        "customer": localStorage.getItem("customer")
      }});
};

export const getSubscriptionList = () => {
  return axios.get(`${getPrivateURL()}subscriptions` );
};
export const getSubscriptionProduct = (product_id) => {
  return axios.get(`${getPrivateURL()}subscriptions/product/${product_id}`, {
      headers:{
        "customer": localStorage.getItem("customer")
      }});
};
export const getSubscriptionItem = (Subscription_id) => {
  return axios.get(`${getPrivateURL()}subscriptions/${Subscription_id}` );
};
export const insertSubscription = (data) => {
    return axios.post(`${getPrivateURL()}subscriptions`, data);
};
export const updateSubscription = (Subscription_id, data) => {
    return axios.put(`${getPrivateURL()}subscriptions/${Subscription_id}`, data);
};
export const processPayment = (data) => {
    return axios.post(`${getPrivateURL()}subscriptions/process_payment`, data);
};
export const removeSubscription = (Subscription_id) => {
    return axios.delete(`${getPrivateURL()}subscriptions/${Subscription_id}` );
};

const SubscriptionService = {
  getSubscriptionList,
  getSubscriptionItem,
  insertSubscription,
  updateSubscription,
  removeSubscription,
  generateToken,
  processPayment,
  getSubscriptionProduct,
};
export default SubscriptionService;

