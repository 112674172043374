

import OverView from "layouts/ecommerce/overview";
import DashboardHome from "layouts/dashboards/smart-home";
import AllProjects from "layouts/pages/profile/all-projects/members";
import Settings from "layouts/pages/account/settings";
import PricingPage from "layouts/pages/pricing-page";
import Wizard from "layouts/applications/wizard";
import ReportViewer from "layouts/applications/report";
import Processes from "layouts/ecommerce/products/products-list";
import SignIn from "layouts/authentication/sign-in/illustration";
import SignUp from "layouts/authentication/sign-up/illustration";
import CompleteRegister from "layouts/authentication/complete-register/illustration";

// Soft UI Dashboard PRO React icons
import Shop from "examples/Icons/Shop";
import SettingsIcon from "examples/Icons/Settings";
import CubeIcon from "examples/Icons/Cube";
import BasketIcon from "examples/Icons/Basket";
import SpaceShipIcon from "examples/Icons/SpaceShip";

import {Navigate} from "react-router-dom";
import {getUserIsLogin, logout} from "./services/auth.services";

import React from "react"
import UploadedData from "layouts/applications/data-tables/categories";
import Subscriptions from "./layouts/plans/subscriptions";
import Plans from "./layouts/plans/plans";
import Users from "layouts/pages/profile/all-projects/members";
import Staffs from "layouts/pages/profile/all-projects/stafs";
import Invoice from "./layouts/pages/account/invoice";
import AfterPayment from "./layouts/applications/after";
// import ReportViewer from "./layouts/applications/report";

const TrueValue = ({component}) => {
  // return component; // for test
  if (getUserIsLogin()){
    return component;
  }else{
    // window.location.reload();

    return <Navigate to={"/authentication/login"} />
  }

};
const DoLogout = () =>{
  // alert("logout")
    logout()
    return <Navigate to={"/authentication/login"} />
}


const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "default",
    route: "/dashboards/home",
    rules: ["all"],
    noCollapse: true,
    icon: <CubeIcon size="12px" />,
    component:  <TrueValue component={<OverView />} />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/dashboards/settings",
    rules: ["all"],
    noCollapse: true,
    icon: <SettingsIcon size="12px" />,
    component: <TrueValue component={<Settings />} />,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    route: "/dashboards/payments",
    rules: ["all"],
    visible: true,
    noCollapse: true,
    icon: <BasketIcon size="12px" />,
    component: <TrueValue component={<Invoice />} />,
  },
  {
    type: "collapse",
    name: "Process",
    key: "process",
    route: "/dashboards/process",
    rules: ["all"],
    visible: true,
    noCollapse: true,
    icon: <SettingsIcon size="12px" />,
    component: <TrueValue component={<Processes />} />,
  },

  // { type: "title", title: "Process", key: "title-pages", rules: ["admin"] },
  // {
  //   type: "collapse",
  //   name: "Process",
  //   key: "process",
  //   visible: true,
  //   rules: ["all"],
  //   icon: <SettingsIcon size="12px" />,
  //   collapse: [
  //
  //     {
  //       name: "Processes",
  //       key: "processes",
  //       rules: ["all"],
  //       route: "/applications/processes",
  //       component: <TrueValue component={<Processes />} />,
  //     },
  //     {
  //       name: "New Process",
  //       key: "new_process",
  //       rules: ["all"],
  //       route: "/applications/new_process",
  //       component: <Wizard />,
  //     },
  //   ],
  // },
  { type: "title", title: "Administrator", key: "title-pages", rules: ["admin"] },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    visible: true,
    rules: ["admin"],
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "Members",
        key: "members",
        route: "/users/members",
        rules: ["admin"],
        component: <TrueValue component={<Users />} />,
      },
      // {
      //   name: "Staffs",
      //   key: "staff",
      //   route: "/users/staff",
      //   visible: false,
      //   rules: ["admin"],
      //   component: <TrueValue component={<Staffs />} />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    visible: true,
    rules: ["admin"],
    icon: <SettingsIcon size="12px" />,
    collapse: [
      // {
      //   name: "Plans",
      //   key: "plans",
      //   visible: false,
      //   route: "/plans/plans",
      //   rules: ["admin"],
      //   component: <TrueValue component={<Plans />} />,
      // },
      {
        name: "Subscriptions",
        key: "subscriptions",
        visible: true,
        route: "/plans/subscriptions",
        rules: ["admin"],
        component: <TrueValue component={<Subscriptions />} />,
      },
    ],
  },

    // for all user
  {
    type: "collapse",
    name: "Hidden",
    key: "hidden",
    visible: false,
    rules: ["all"],
    icon: <SettingsIcon size="12px" />,
    collapse: [
      {
        name: "sign-in",
        key: "login",
        rules: ["all"],
        route: "/authentication/login",
        component: <SignIn />,
      },
      {
        name: "sign-up",
        key: "register",
        rules: ["all"],
        route: "/authentication/register",
        component: <SignUp />,
      },
      {
        name: "complete-register",
        key: "complete-register",
        rules: ["all"],
        route: "/authentication/complete-register",
        component: <CompleteRegister />,
      },
      {
        name: "pricing",
        key: "pricing",
        rules: ["all"],
          route: "/dashboards/pricing",
        component: <PricingPage />,
      },
      {
        name: "New Process",
        key: "new_process",
        rules: ["all"],
        route: "/applications/new_process",
        component: <Wizard />,
      },
      {
        name: "Report",
        key: "report",
        rules: ["all"],
        route: "/applications/report",
        component: <TrueValue component={<ReportViewer />} />,
      },
      {
        name: "Report",
        key: "report",
        rules: ["all"],
        route: "/order",
        component: <TrueValue component={<AfterPayment />} />,
      },


    ],

  },

  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/dashboards/logout",
    rules: ["all"],
    noCollapse: true,
    icon: <SpaceShipIcon size="12px" />,
    component: <TrueValue component={<DoLogout />} />,
  },
 
];

export default routes;
