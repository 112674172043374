

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import SoftTagInput from "components/SoftTagInput";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { useEffect, useState } from "react";
import  UserService from "services/user.service";

import { toast } from 'react-toastify';

function BasicInfo() {
  const [values, setValues] = useState({})
  const handleChange = (value) => {
      setValues(value);
  };
  useEffect(()=>{
    UserService.getMe() .then((data)=>{
      let vales = data.data.data;
      setValues({
        "id":vales["id"],
        "first_name":vales["first_name"],
        "last_name":vales["last_name"],
        "email":vales["email"],
        "location":vales["location"],
        "phone":vales["phone"]
      })
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
    });
  }, [setValues]);
  const doChange = () => {
    UserService.updateUser(values.id, values) .then((data)=>{
      toast.success("data saved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
      });
      // window.location.reload();
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
    });
  }

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Info</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="first name"
              value={values.first_name}
              onChange={(event) => {
                handleChange({ ...values, first_name: event.target.value })
              }}
              placeholder="Alec" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
                label="last name"
                value={values.last_name}
                onChange={(event) => {
                  handleChange({ ...values, last_name: event.target.value })
                }}
                placeholder="Thompson" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
                label="your location"
                value={values.location}
                onChange={(event) => {
                  handleChange({ ...values, location: event.target.value })
                }}
                placeholder="Sydney, A" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="phone number"
              value={values.phone}
              onChange={(event) => {
                handleChange({ ...values, phone: event.target.value })
              }}
              inputProps={{ type: "number" }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              label="email (Read Only)"
              placeholder="example@email.com"
              value={values.email}
              inputProps={{ type: "email" }}
              readonly />
          </Grid>
          <Grid item xs={12} md={2} >
            <SoftButton variant="gradient" color="dark" onClick={doChange}>
              Save Settings
            </SoftButton>
          </Grid>
          
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
