
/* eslint-disable react/prop-types */
// Soft UI Dashboard PRO React components
import SoftBadge from "components/SoftBadge";

// ProductsList page components
import ProductCell from "layouts/ecommerce/products/products-list/components/ProductCell";
import ActionCell, {ActionCellWithDownload} from "layouts/ecommerce/products/products-list/components/ActionCell";

// Images
import adidasHoodie from "assets/images/ecommerce/adidas-hoodie.jpeg";
import macBookPro from "assets/images/ecommerce/macbook-pro.jpeg";
import metroChair from "assets/images/ecommerce/metro-chair.jpeg";
import alchimiaChair from "assets/images/ecommerce/alchimia-chair.jpeg";
import fendiCoat from "assets/images/ecommerce/fendi-coat.jpeg";
import offWhiteJacket from "assets/images/ecommerce/off-white-jacket.jpeg";
import yohjiYamamoto from "assets/images/ecommerce/yohji-yamamoto.jpeg";
import mcqueenShirt from "assets/images/ecommerce/mcqueen-shirt.jpeg";
import yellowChair from "assets/images/ecommerce/yellow-chair.jpeg";
import heronTshirt from "assets/images/ecommerce/heron-tshirt.jpeg";
import livingChair from "assets/images/ecommerce/living-chair.jpeg";
import orangeSofa from "assets/images/ecommerce/orange-sofa.jpeg";
import burberry from "assets/images/ecommerce/burberry.jpeg";
import dgSkirt from "assets/images/ecommerce/d&g-skirt.jpeg";
import undercover from "assets/images/ecommerce/undercover.jpeg";
import StatusCell from "../../ecommerce/orders/order-list/components/StatusCell";
import {React, useEffect, useState} from "react";
import {getPlanList} from "../../../services/plan.service";
import {toast} from "react-toastify";
import {getProcessList} from "../../../services/process.service";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import SoftTypography from "../../../components/SoftTypography";
import Tooltip from "@mui/material/Tooltip";

// Badges
const removedBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="removed" container />
);
const disabledBadge = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="removed" container />
);
const activeBadge = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Active" container />
);

export function loadProcessData(onClickItem){
  const columns = [
    { Header: "Id", accessor: "process_id"},
    { Header: "Create", accessor: "create" },
    { Header: "User", accessor: "user" },
    { Header: "Type", accessor: "type" },
    { Header: "Status", accessor: "status",
      Cell: ({ value }) => {
        let status;
        if (value === "draft") {
          status = <StatusCell icon="mode" color="dark" status="Draft" />;
        }
        if (value === "finished") {
          status = <StatusCell icon="done" color="success" status="Finished" />;
        }
        if (value === "progress") {
          status = <StatusCell icon="alarm" color="info" status="Progress" />;
        }
        if (value === "error") {
          status = <StatusCell icon="close" color="error" status="Error" />;
        }
        if (value === "blocked") {
          status = <StatusCell icon="close" color="error" status="Blocked" />;
        }
        return status ;
      }
    },
    { Header: "action", width: "13%", accessor: "id" ,
      Cell: props  => {
        // console.log(props.value, )
        let i = 0;
        for (const dataKey in props.data) {
          if(props.value === props.data[dataKey].id){
              break
          }
          i++;
        }
        return onClickItem !== undefined && <ActionCellWithDownload onClick={onClickItem}
                                                                    rowId={props.value}
                                                                    status={props["data"][i]["status"]}  />
      },
    },
  ]

  const [rows, setRows] = useState([])
  function requestServer(rowsSetter){

    getProcessList()
      .then((data)=>{
        rowsSetter(data.data.data)
      }).catch((e)=>{
        toast.error(e.response.data.status, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  }
  useEffect(()=>{
    requestServer(setRows)
  }, [setRows]);
  return {
    columns : columns,
    rows: rows
  }
}



