
import {useEffect, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/pricing-page/components/Header";
import Footer from "layouts/pages/pricing-page/components/Footer";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
import Faq from "layouts/pages/pricing-page/components/Faq";
import {toast} from "react-toastify";
import PlanService from "../../../services/plan.service";
import SoftButton from "../../../components/SoftButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Icon from "@mui/material/Icon";
import userService from "../../../services/user.service";
import SoftTypography from "../../../components/SoftTypography";

function PricingPage() {
    const [tabValue, setTabValue] = useState(0);
    const [activePlan, setActivePlan] = useState("");
    const [expireDate, setExpireDate] = useState("");
    // const [prices, setPrices] = useState(["Free", "10", "25"]);
    const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    useEffect(()=>{
        userService.getPaymentMethod().then((data)=> {
            if(data.data.data["has_active_plan"]){
                setActivePlan(data.data.data["subscription"]["plan_name"])
                setExpireDate(data.data.data["subscription"]["remaining_days"])
                handleOpenDialog()
            }
        }).catch((e)=>{
            // toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
        })
    },[])
    const handleAgreeDialog = (e) =>{
      window.location.href = "/dashboard"
    }
    ////////////////////////////////////////////////////   -> Render
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const [openDialog,setOpenDialog] = useState(false);
    const renderDialog = () => {
        return (
            <Dialog open={openDialog} keepMounted onClose={handleCloseDialog} aria-describedby="alert-dialog-slide-description" >
                <DialogTitle><Icon color={"info"} sx={{"marginRight":"0.5rem"}} >info</Icon>{"You Have an active subscription plan"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                      Current Active Plan : <SoftTypography variant="h6" color={"dark"} fontWeight={"bold"}>{activePlan}</SoftTypography> <br/>
                      Expire At : <SoftTypography variant="h6" color={"dark"} fontWeight={"bold"}>Next {expireDate} days</SoftTypography> <br/>
                      Do you want to change it ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <SoftButton onClick={handleAgreeDialog} >No Back to dashboard</SoftButton>
                  <SoftButton onClick={handleCloseDialog}  size={"large"}  color={"info"}>Yes, I want to change it</SoftButton>
                </DialogActions>
            </Dialog>
        )
    }
  return (
    <PageLayout >
      <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
        {renderDialog()}
      {/*<Container  >*/}
        <PricingCards  />
      {/*</Container>*/}
      {/*<SoftBox mt={8}>*/}
        <Footer />
      {/*</SoftBox>*/}
    </PageLayout>
  );
}

export default PricingPage;
