import axios from "axios";
import {getPrivateURL, getPublicURL} from "./core.service";
// import {hasActivePlan} from "./auth.services";

// axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('access_token')}`;

const editProfile = (country_id) => {
    return axios.get(`${getPrivateURL()}provinces/${country_id.toString()}`)
};

const getProfile = () =>{
    return axios.post(`${getPrivateURL()}users/me/`, {} )
}

const changePassword = (values) =>{
    return axios.put(`${getPrivateURL()}users/change_password` , values)
}
const changePaymentMethod = (values) =>{
    return axios.put(`${getPrivateURL()}users/change_payment_method` , values)
}
const updateUser = (user_id, values) =>{
    return axios.put(`${getPrivateURL()}users/` + user_id , values)
}

const changeAvatar = (avatarFormData) =>{
    return  axios.post(
        `${getPrivateURL()}users/change_avatar/` ,
        avatarFormData, {headers: {'Content-Type': 'multipart/form-data'}}
    )
}

export const getUsers = (role) => {
    if (role === "user")
        return axios.get(`${getPrivateURL()}users` )
    else
        return axios.get(`${getPrivateURL()}staffs`)
}

export const getMe = () => { 
    return axios.get(`${getPrivateURL()}me`)
}

export const loadLastInvoice = () => {
    return axios.get(`${getPrivateURL()}last_invoice` )
}
export const getPaymentMethod = () => { 
    return axios.get(`${getPrivateURL()}my_payment_method` )
}
export const cancelSubscription = () => {
    return axios.delete(`${getPrivateURL()}cancel_subscription` )
}
export const getUser = (user_id) => axios.get(`${getPrivateURL()}users/` + user_id )
export const createUser = (data) => {
    return axios.post(`${getPrivateURL()}users` , data)
};
export const addCard = (data) => {
    return axios.post(`${getPrivateURL()}add_card` , data)
};
export const resendConfirmEmail = (user_id) => {
    return axios.post(`${getPrivateURL()}users/${user_id}/resend_confirm` , null)
};
export const deleteUser = (user_id) => {
    return axios.delete(`${getPrivateURL()}users/${user_id}` )
};

// export const hasActivePlan = () => {
//     return localStorage.getItem("") === false
// };

export const userRole = () => {
    return localStorage.getItem("permission")
};

export const isAdminLogin = () => {
    return localStorage.getItem("permission") === "admin"
};


export const isMemberLogin = () => {
    return localStorage.getItem("permission") === "member"
};


export const hasActivePlan = () => {
    if (isAdminLogin()){
        return true;
    }else{
        return (localStorage.getItem("has_plan") !== undefined &&  localStorage.getItem("has_plan") !== null)
    }
};


const UserService = {

  createUser,
  addCard,
  editProfile,
  changeAvatar,
  changePassword,
  updateUser,
  resendConfirmEmail,
  deleteUser,
  getProfile,
  getUsers,
  getUser,
  getMe,
  getPaymentMethod,
  changePaymentMethod,
  cancelSubscription,
  loadLastInvoice,
  hasActivePlan,
};
export default UserService;

