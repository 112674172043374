
import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";

// Overview page components
import ChannelsChart from "layouts/ecommerce/overview/components/ChannelsChart";

// Data
import defaultLineChartData from "layouts/ecommerce/overview/data/defaultLineChartData";
import horizontalBarChartData from "layouts/ecommerce/overview/data/horizontalBarChartData";
import salesTableData from "layouts/ecommerce/overview/data/salesTableData";
import dataTableData from "layouts/ecommerce/overview/data/dataTableData";
import DashboardService from "../../../services/dashboard.service";
import {toast} from "react-toastify";
import SoftSnackbar from "../../../components/SoftSnackbar";
import SoftAlert from "../../../components/SoftAlert";
import UserService, {isAdminLogin, isMemberLogin} from "../../../services/user.service";
import {loadProcessData} from "../../applications/data/process_data";
import {loadUserData} from "../../applications/data/user_data";
import ProcessService from "../../../services/process.service";
import {getPublicURL} from "../../../services/core.service";

function Overview() {

    const [revenue, setRevenue] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [planCount, setPlanCount] = useState(0);
    const [memberCount, setMemberCount] = useState(0);
    const [subscription, setSubscription] = useState(0);
    const [doneProcessCount, setDoneProcessCount] = useState(0);
    const [inProgressProcessCount, setInProgressProcessCount] = useState(0);
  const [hasActivePlan, setHasActivePlan] = useState(true);
  useEffect(()=>{
     UserService.getPaymentMethod()
        .then((data)=>{

            localStorage.removeItem("has_plan")
            if(data.data.data["has_active_plan"]){
                localStorage.setItem("has_plan", "1");
            }
          setHasActivePlan(data.data.data["has_active_plan"])
        }).catch((e)=>{
          toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
        })
    if (isAdminLogin()){

    DashboardService.getDashboard()
        .then((data)=>{
          setPlanCount(data.data.data["plan_count"])
          setMemberCount(data.data.data["member_count"])
          setInProgressProcessCount(data.data.data["process_in_progress_count"])
          setDoneProcessCount(data.data.data["process_done_count"])
          setFailedCount(data.data.data["process_failed_count"])
          setSubscription(data.data.data["subscription_count"])
          setMemberCount(data.data.data["member_count"])
          setRevenue(data.data.data["revenue"])
        }).catch((e)=>{
          toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
        })
    }
  },[setHasActivePlan])

      const downloadData = (id) =>{
        // let fileName = "Sample Data - Inspection-Test.csv";
        saveAs(getPublicURL() + "processes/raw/" + id, "raw.csv");
    }
      const onClickItem = (event) =>{
        console.log(event.target)
        let actionType = event.target.getAttribute("data-type")
         if(actionType === "download"){
            downloadData(event.target.getAttribute("data-row"))
        }else if(actionType === "report"){
            window.location.href = "/applications/report?id=" + event.target.getAttribute("data-row")
        }
    }

    const emptyAction = (e) =>{

    }
    const renderAlert = () => {
      if (!hasActivePlan && isMemberLogin()){
        return (<SoftAlert color={"error"} dismissible={false}>
                  <SoftTypography variant="h5" fontWeight={"bold"} color="white">
                    You don't have any active plan!
                  </SoftTypography>
                  <SoftButton sx={{"marginLeft":"1rem"}} href={"/dashboards/pricing"} target="_blank" size={"large"}  component={"a"}  fontWeight={"bold"} color={"light"}>
                      Select A Plan
                  </SoftButton>
              </SoftAlert>)
      }
      return <></>
    };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderAlert()}

      <SoftBox py={3}>
        <SoftBox sx={{"display":isAdminLogin() ? "block" : "none"}} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="In Progress Process"
                count={inProgressProcessCount}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{action: emptyAction,
                  value: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Finished Process"
                count={doneProcessCount}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{action: emptyAction,
                  value: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Subscription"
                count={subscription}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{action: emptyAction,
                   value: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Members"
                count={memberCount}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{
                  action: emptyAction,
                  value: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="plans"
                count={planCount}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{
                  action: emptyAction,
                  value: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Failed Process"
                count={failedCount}
                percentage={{color: "secondary", value: "", label: "",}}
                dropdown={{
                  action: emptyAction,
                  value: "",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3} sx={{"display":"none"}}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6} lg={4}>
              <ChannelsChart />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title="Revenue"
                description={
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox display="flex" ml={-1}>
                      <SoftBadgeDot color="info" size="sm" badgeContent="Facebook Ads" />
                      <SoftBadgeDot color="dark" size="sm" badgeContent="Google Ads" />
                    </SoftBox>
                    <SoftBox mt={-5.25} mr={-1}>
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <SoftButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                }
                chart={defaultLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3} sx={{"display":"none"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <HorizontalBarChart title="Sales by age" chart={horizontalBarChartData} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid sx={{"display":isAdminLogin() ? "block" : "none"}}  mb={3} container spacing={3} >
          <Grid item xs={12}>
            <Card>
              <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Latest Members
                </SoftTypography>
              </SoftBox>
              <SoftBox py={1}>
                  {isAdminLogin() && <DataTable
                    table={loadUserData("user", undefined)}
                    entriesPerPage={{defaultValue: 50, entries: [50, 100]}} canSearch  showTotalEntries={true}/>
                  }
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
        <Grid mb={3} container spacing={3} >
          <Grid item xs={12}>
            <Card>
              <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                    {isAdminLogin() ? "Latest" : "Yours"} Processes
                </SoftTypography>
              </SoftBox>
              <SoftBox py={1}>
                <DataTable
                  table={ loadProcessData(onClickItem)}
                  entriesPerPage={{defaultValue: 50, entries: [50, 100]}} canSearch showTotalEntries={true}
                />
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
