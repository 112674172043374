
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import {useEffect, useState} from "react";
import UserService from "../../../../../../services/user.service";
import {toast} from "react-toastify";
import AuthService from "../../../../../../services/auth.services";
import * as Yup from "yup";
import YupPassword from 'yup-password';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import {TextField} from "@mui/material";

YupPassword(Yup);

const defaultInputValues = { currentPassword: '', newPassword: '', passwordConfirmation: ''};

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .min(6, 'Current Password must be at least 8 characters')
        .required('Current Password is required'),
    newPassword: Yup.string()
        .required('New Password is required')
        .minNumbers(1, 'password must contain at least 1 number')
        .minLowercase(1, 'password must contain at least 1 lower case letter')
        .minUppercase(1, 'password must contain at least 1 upper case letter')
        .min(8, 'New Password must be at least 6 characters'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

function ChangePassword() {
  const passwordRequirements = [
    "One special characters",
    "Min 8 characters",
    "One number (2 are recommended)",
    "One LowerCase (2 are recommended)",
    "One UpperCase (2 are recommended)",
    "Change it often",
  ];

    const [values, setValues] = useState(defaultInputValues);

    const { register, handleSubmit, formState: { errors , isValid } } = useForm({mode:"all", resolver:yupResolver(validationSchema)});


  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <SoftBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <SoftTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </SoftTypography>
      </SoftBox>
    );
  });
    const handleChange = (value) => setValues(value);
  useEffect(()=>{
    // UserService.getMe() .then((data)=>{
    //   let vales = data.data.data;
    //   setValues({
    //     "id":vales["id"],
    //     "current_password":"",
    //     "new_password":"",
    //     "confirm_password":"",
    //   })
    // }).catch((e)=>{
    //   toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
    // });
  }, []); // setValues

  const doChange = (e) => {
    UserService.changePassword(values) .then((data)=>{
      toast.success("data saved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
      });
      AuthService.logout();
      window.location.reload();
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
    });
  }

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">Change Password</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
                fullWidth
                variant={"filled"}
              name="currentPassword"
              // label="Current Password"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
              value={values.currentPassword}
              {...register("currentPassword", {required:true})}
              onChange={(event) =>
                  handleChange({ ...values, currentPassword: event.target.value })}
              inputProps={{ type: "password", autoComplete: "", placeholder:"CurrentPassword" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              // label="New Password"
              name="newPassword"
                variant={"filled"}
              {...register('newPassword')}
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              value={values.newPassword}
              onChange={(event) =>
                  handleChange({ ...values, newPassword: event.target.value })}
              inputProps={{ type: "password", autoComplete: "", placeholder:"New Password" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="passwordConfirmation"
                variant={"filled"}
              // label="Confirm New Password"
              {...register('passwordConfirmation')}
              error={!!errors.passwordConfirmation}
              helperText={errors.passwordConfirmation?.message}
              value={values.passwordConfirmation}
              onChange={(event) => handleChange({ ...values, passwordConfirmation: event.target.value })}
              inputProps={{ type: "password", autoComplete: "", placeholder:"Confirm New Password" }}
            />
          </Grid>
        </Grid>
        <SoftBox mt={6} mb={1}>
          <SoftTypography variant="h5">Password requirements</SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </SoftTypography>
        </SoftBox>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </SoftBox>
          <SoftBox ml="auto">
            <SoftButton variant="gradient" color="dark" size="small"  disabled={!isValid} onClick={doChange}>
              update password
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
