
import {useEffect, useState} from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import rocket from "assets/images/logo_light.svg";
import tick from "assets/images/tick-green-icon.png";
import {toast} from "react-toastify";
import AuthService from "../../../../services/auth.services";
function Illustration() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const m ={
        'activation': new URLSearchParams(search).get('activation'),
        'email': new URLSearchParams(search).get('email').replace(/\s/g, "+"),
      }
    useEffect(()=>{
      AuthService.confirmAccount(m)
          .then((data)=>{
            // toast.success("Your account activate now",
            // {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
                setTimeout(function () {
                        AuthService.confirmAccount(m)
                            .then((e)=>{ navigate("/authentication/login"); })
                            .catch((e)=>{ navigate("/authentication/login"); })
                }, 3000);

          }).catch((e)=>{
            toast.error(e.response.data.status,
                {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
          })
    }, [])

  return (
          <IllustrationLayout
              title="Registration Completed"
              description="Login to your account and choose a plan to start using RBR"
              headerImage={tick}
              illustration={{
                image: rocket,
                title: "Redirecting to login page .....",
                description: "You can select free plan just fill the card form"}}
            >

          </IllustrationLayout>


  );
}

export default Illustration;
