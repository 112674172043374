import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  PaymentElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import SoftButton from "../../../../../../components/SoftButton";
import SoftInput from "../../../../../../components/SoftInput";
import SoftTypography from "../../../../../../components/SoftTypography";
import UserService from "../../../../../../services/user.service";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
export default function PaymentForm() {
    const schema = {
        card_name: Yup.string() .min(4, 'Plan Name must be at least 4 characters') .required('Plan Name is required'),
        card_number: Yup.string() .min(4, 'Plan Name must be at least 4 characters') .required('Plan Name is required'),
        card_expire_month: Yup.number() .min(10, 'Plan Description must be at least 10 characters') .required('Plan Description is required'),
        card_expire_year:  Yup.number().positive().min(0, "minimum 0").required("price is required"),
        card_cvv:  Yup.number().positive(),
    }
    const defaultInputValues = {
        card_name: "",
        card_number: "",
        card_expire_month:0,
        card_expire_year:0,
        card_cvv:0,
    };

    const [values, setValues] = useState(defaultInputValues);
    const handleChange = (value) => {
        setValues(value);
        setIsValid(validationSchema.isValidSync(values));
    };
    const validationSchema = Yup.object().shape(schema);
     const { register, handleSubmit, formState: { errors  } } =
         useForm({mode:"all", resolver:yupResolver(validationSchema)});
    const [isValid, setIsValid] = useState(false);

  const doAction = (event) =>{
    UserService.addCard(values).then((data)=>{
      window.location.reload();
    }).catch((e)=>{
        toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
    })
  }
  return (
    <React.Fragment>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <SoftTypography color={"text"} textGradient={true} opacity={0.6} fontWeight={"bold"} >FullName On Card</SoftTypography>
          <SoftInput
            required
            fullWidth
            autoComplete={"none"}
            size={"large"}
            variant="standard"
            error={!!errors.card_name}
          helperText={errors.card_name?.message}
          value={values.card_name}
          {...register("card_name")}
          onChange={(event) => {
            handleChange({ ...values, card_name: event.target.value })
          }}
        />
        </Grid>
        <Grid item xs={12} md={12}>
          <SoftTypography color={"text"} textGradient={true} opacity={0.6} fontWeight={"bold"} >Card number</SoftTypography>
          <SoftInput
            required
            fullWidth
            autoComplete={"none"}
            size={"large"}
            variant="standard"
             error={!!errors.card_number}
          helperText={errors.card_number?.message}
          value={values.card_number}
          {...register("card_number")}
          onChange={(event) => {
            handleChange({ ...values, card_number: event.target.value })
          }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SoftTypography color={"text"} textGradient={true} opacity={0.6} fontWeight={"bold"} >Expire Month</SoftTypography>
          <SoftInput
            required
            fullWidth
            type={"number"}
            autoComplete={"none"}
            size={"large"}
            variant="standard"
             error={!!errors.card_expire_month}
          helperText={errors.card_expire_month?.message}
          value={values.card_expire_month}
          {...register("card_expire_month")}
          onChange={(event) => {
            handleChange({ ...values, card_expire_month: event.target.value })
          }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SoftTypography color={"text"} textGradient={true} opacity={0.6} fontWeight={"bold"} >Expire Year</SoftTypography>
          <SoftInput
            required
            fullWidth
            type={"number"}
            autoComplete={"none"}
            size={"large"}
            variant="standard"
             error={!!errors.card_expire_year}
          helperText={errors.card_expire_year?.message}
          value={values.card_expire_year}
          {...register("card_expire_year")}
          onChange={(event) => {
            handleChange({ ...values, card_expire_year: event.target.value })
          }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SoftTypography color={"text"} textGradient={true} opacity={0.6} fontWeight={"bold"} >CVV</SoftTypography>
          <SoftInput
            required
            fullWidth
            type={"number"}
            autoComplete={"none"}
            size={"large"}
            variant="standard"
            error={!!errors.card_cvv}
            helperText={errors.card_cvv?.message}
            value={values.card_cvv}
            {...register("card_cvv")}
          onChange={(event) => {
            handleChange({ ...values, card_cvv: event.target.value })
          }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SoftButton color={"success"} size={"large"} variant={"gradient"} onClick={doAction}>Insert Card</SoftButton>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}