
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Wizard application components
import FormField from "layouts/applications/wizard/components/FormField";

// Images
import team2 from "assets/images/team-2.jpg";
import {useEffect, useState} from "react";
import Office from "../../../../../examples/Icons/Office";
import Basket from "../../../../../examples/Icons/Basket";

function FilterType({stepValidator, handleNext}) {
  const [pressurized, setPressurized] = useState(false);
  const [nonePressurized, setNonePressurized] = useState(false);
  useEffect((e)=>{
      stepValidator(false)
    if (localStorage.getItem("ft") === "pressurized"){
      handleSetPressurized();
    }else if(localStorage.getItem("ft") === "none_pressurized"){
      handleSetNonePressurized();
    }
  }, [setPressurized, setNonePressurized])
  const handleSetPressurized = (e) => {
    setPressurized(true)
    setNonePressurized(false)
    localStorage.setItem("ft", "pressurized");
      stepValidator(true);

     if (e !== null && e !== undefined && e.target !== null && e.target !== undefined){
      handleNext()
    }
  };
  const handleSetNonePressurized = (e) =>  {
    setPressurized(false)
    setNonePressurized(true)
    localStorage.setItem("ft", "none_pressurized");
      stepValidator(true)

     if (e !== null && e !== undefined && e.target !== null && e.target !== undefined){
      handleNext()
    }
  };

   const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,
    color:"",

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
      backgroundColor: `#69af35 !important`,
    },
    "&:hover": {
      backgroundColor: `#69af35 !important`,
      border: `${borderWidth[2]} solid ${secondary.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
      },
    },
  });


  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          {/*<SoftTypography variant="h5" fontWeight="regular">*/}
          {/*  Q5.*/}
          {/*</SoftTypography>*/}
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          What kind of facility do you want to assets ?
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton color="secondary" variant={pressurized ? "contained" : "outlined"} onClick={handleSetPressurized} sx={customButtonStyles}>
                <Office size="24px" color={pressurized ? "white" : "dark"} />
              </SoftButton>
              <SoftTypography variant="h6">Pressurized</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton color="secondary" variant={nonePressurized ? "contained" : "outlined"} onClick={handleSetNonePressurized} sx={customButtonStyles}>
                <Basket size="24px" color={nonePressurized ? "white" : "dark"} />
              </SoftButton>
              <SoftTypography variant="h6">Non-Pressurized</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default FilterType;
