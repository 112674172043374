
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import Express from "assets/images/logos/express.png";
import React, { useState } from "react";
import { useEffect } from "react";
import { getPaymentMethod, cancelSubscription } from "services/user.service";

import { toast } from 'react-toastify';
import UserService from "services/user.service";
import PaymentForm from "../PaymentMethodForm/PaymentForm";
import Fade from "@mui/material/Fade";
import {ModalStyleHalf} from "../../../../../modalStyle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CheckoutForm from "../../../../../../examples/Checkout/CheckoutForm";
import {Modal} from "@mui/material";
import IconButton from "@mui/material/IconButton";


function PaymentMethod() {
  const { borderWidth, borderColor } = borders;

  const [cardsList, setCardsList] = useState([]);
  const [activeCardId, setActiveCardId] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [activePlanName, setActivePlanName] = useState("");
  const [activePlanStart, setActivePlanStart] = useState("");
  const [activePlanRemaining, setActivePlanRemaining] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [renewalPlan, setRenewalPlan] = useState("");
  useEffect(()=>{
    getPaymentMethod() .then((data)=>{
      setCardsList(data.data.data["cards"])
      setRenewalDate(data.data.data["renewal_date"])
      setRenewalPlan(data.data.data["renewal_plan"])
      setCardsList(data.data.data["cards"])
      setActiveCardId(data.data.data["active_card_id"])
      setActivePlanName(data.data.data["subscription"]["plan_name"])
      setActivePlanStart(data.data.data["subscription"]["start"])
      setActivePlanRemaining(data.data.data["subscription"]["remaining_days"])
    }).catch((e)=>{
      toast.error(e, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
    });
  }, [setActiveCardId, setActiveCardId,setCardsList,setActivePlanName,setActivePlanStart,setActivePlanRemaining,]);

   const doCancel = (event) =>{
      if(confirm("Do you want cancel your current subscription ?")){
          cancelSubscription().then((data)=>{
              localStorage.removeItem("has_plan")
              window.location.reload()
          }).catch((e)=>{
            toast.error(e.response.data.status, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
              hideProgressBar: false,
            });
          })
      }
  }

  function changePaymentMethod(e){
    UserService.changePaymentMethod(activeCardId)
    .then((data)=>{
      toast.success("data saved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
      });
      window.location.reload();
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
    });
  }
  function addNewPaymentMethod(e){
    UserService.addNewPaymentMethod(paymentMethodId)
    .then((data)=>{
      toast.success("data saved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
      });
      window.location.reload();
    }).catch((e)=>{
      toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false,});
    });
  }

  const [cardModalShow, setCardModalShow] = useState(false)

    const handleShowModal = (e) => setCardModalShow(true)
    const handleCloseModal = (e) => setCardModalShow(false)
  const renderInsertCardModal = () =>{
    return (
        <Modal open={cardModalShow}>
            <Fade in={cardModalShow}>
              <Card sx={ModalStyleHalf}>
                  <Grid container spacing={2} fontWeight={"bold"} >
                      <Grid item md={11}>
                          <Box p={2} mb={2}>
                            <Typography variant="h5">Add Payment Method</Typography>
                          </Box>
                      </Grid>
                      <Grid item md={1}>
                          <IconButton  aria-label="close" onClick={handleCloseModal}>
                               <Icon >close</Icon>
                            </IconButton>
                      </Grid>
                  </Grid>
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                      <Box component="form" pb={1} px={1}>
                          <Grid container spacing={1}>

                              <Grid item xs={12} md={12}>
                                  <PaymentForm />
                              </Grid>
                        </Grid>
                      </Box>
                  </Card>
              </Card>
            </Fade>
          </Modal>
    )
  }

   const renderedCardList = cardsList && cardsList.map((item, key) => {
      // const itemKey = `element-${key}`;

      return (
        <Grid item xs={12} md={6}>
            <SoftBox border={`${borderWidth[1]} solid ${borderColor}`} borderRadius="lg" display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox component="img" src={item["type"] === "mastercard" ? masterCardLogo : (item["type"] === "amex" ? Express :  visaLogo) } alt="master card" width="10%" mr={2} />
              <SoftTypography variant="h6" fontWeight="medium">
                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{item["last_4_number"]}
              </SoftTypography>
              <SoftBox ml="auto" lineHeight={0} style={{"display":"none"}}>
                <Tooltip title="Edit Card" placement="top">
                  <Icon sx={{ cursor: "pointer" }} fontSize="small">
                    delete
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          </Grid>
      );
  });


  return (
    <Card id="delete-account">
      <SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        {/*{renderInsertCardModal()}*/}
        <SoftTypography variant="h6" fontWeight="medium">
          Payment Method
        </SoftTypography>
        {/*<SoftButton variant="gradient" color="dark" onClick={handleShowModal} disabled={activePlanRemaining === 0} >*/}
        {/*  <Icon sx={{ fontWeight: "bold" }}>add</Icon>*/}
        {/*  &nbsp;add new card*/}
        {/*</SoftButton>*/}
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          {renderedCardList}
        </Grid>
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}> {/*Trial        remaining days: 10        renewal date: 2023/11/12      renewal plan: Basic ($1,999)*/}
            <SoftBox border={`${borderWidth[1]} solid ${borderColor}`} borderRadius="lg" display="flex" justifyContent="space-between" alignItems="center" p={3}>
              {/*<SoftTypography color={"text"} variant={"h6"} verticalAlign={"sub"} fontWeight={"bold"}>Your Active Plan : </SoftTypography>*/}
              <SoftTypography color={"dark"} variant={"h6"} verticalAlign={"sub"} opacity={0.6} fontWeight={"bold"}>{activePlanName} </SoftTypography>
              <SoftTypography color={"dark"} variant={"h6"} verticalAlign={"sub"} opacity={0.6} fontWeight={"bold"}>Remaining Days: {activePlanRemaining}</SoftTypography>
              <SoftTypography color={"dark"} variant={"h6"} verticalAlign={"sub"} opacity={0.6} fontWeight={"bold"}>Renewal Date: {renewalDate}</SoftTypography>
              <SoftTypography color={"dark"} variant={"h6"} verticalAlign={"sub"} opacity={0.6} fontWeight={"bold"}>Renewal Plan: {renewalPlan}</SoftTypography>
              {/*<SoftTypography color={"inherit"} variant={"h6"} verticalAlign={"sub"}  fontWeight={"bold"}> {activePlanStart}  Days Remaining </SoftTypography>*/}
              <SoftButton variant={"contained"} size={"medium"}
                          onClick={(e)=>window.location.href = "/dashboards/pricing"}
                          color={"dark"}  ><Icon sx={{"marginRight":"10px"}}>edit</Icon>
                {activePlanRemaining > 0 ? "Change Active Plan" : "Select Plan"}</SoftButton>
            </SoftBox>
          </Grid>
        </Grid>

      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <SoftBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
            <SoftTypography color={"dark"} variant={"h6"} verticalAlign={"sub"} opacity={0.6} fontWeight={"bold"}>Do you want to cancel the subscription ?</SoftTypography>
            <SoftButton disabled={activePlanRemaining === 0} onClick={doCancel}  variant={"contained"} size={"medium"} color={"dark"}  >
                <Icon sx={{"marginRight":"10px"}}>cancel</Icon>Cancel Subscription</SoftButton>
            </SoftBox>
          </Grid>
        </Grid>

      </SoftBox>
    </Card>
  );
}

export default PaymentMethod;
