import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
    DataGridPro,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {GridEventListener} from "@mui/x-data-grid-pro";

import * as XLSX from "xlsx";
import AuthServices from "../../../../../services/auth.services";
import CoreService from "../../../../../services/core.service";
import ProcessService from "../../../../../services/process.service";
import {toast} from "react-toastify";
import SoftButton from "../../../../../components/SoftButton";
import Icon from "@mui/material/Icon";
export default function DataGridProDemo({rows, setRows, cols, onCompleted}) {
    const apiRef = useGridApiRef();
    const handleStartEvent: GridEventListener<'cellEditStart'> = ( params,  event  ) => {
      console.log("handleStartEvent")
    }
    const handleStopEvent: GridEventListener<'cellEditStop'> = ( params,  event  ) => {
      console.log("handleStopEvent")
    }
      useEffect(()=>{
        apiRef.current.subscribeEvent( 'cellEditStart', handleStartEvent);
        apiRef.current.subscribeEvent( 'cellEditStop', handleStopEvent);

        if (rows.length === 0){
            handleAddRow();
        }
      }, [apiRef, setRows])
    String.prototype.toProperCase = function () {
        return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    };

    const createRandomRow = () =>{
        const newId = rows.length + 1;
        let buildYourFacility = CoreService.getProcessType().toLowerCase().includes("build")
        let stepItem = CoreService.getProcessType().split("->", -1).map((data)=>{return data.trim()})
        console.log(stepItem)
        if (rows.length > 1){
            const latestRow = rows[rows.length-1]
            return {
                "Segment Number":newId ,
                "Facility Name":latestRow["Facility Name"] ,
                "Component":latestRow["Component"] ,
                "Sub-component":latestRow["Sub-component"] ,
                "Install Year *":latestRow["Install Year *"] ,
                "Model":latestRow["Model"] ,
            }
        }
        return {
            "Segment Number":newId ,
            "Facility Name":"XYZ" ,
            "Component":buildYourFacility ? "" : stepItem[1],
            "Sub-component":buildYourFacility ? "" : stepItem[2],
        }
    }
  const [selectedIds, setSelectedIds] = React.useState([]);

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, createRandomRow()]);
  };
  const handleDeleteRow = () => {
    if(confirm("Do you want to delete selected item(s) ?")){
        // const selectedIDs = new Set(selectionModel);
        setRows((r) => r.filter((x) => !selectedIds.includes(x["Segment Number"])));
    }
  };
    // const processRowUpdate = React.useCallback(async (oldRow, newRow) => {
    //     const updatedRow = { ...newRow, isNew: false };
    //     setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //
    //     console.log(oldRow, newRow)
    //     return updatedRow;
    // }, [rows]);
    // const onProcessRowUpdateError = React.useCallback((err) => {
    //     console.log("Error!");
    // }, []);

    function handleExport(e) {
        if(confirm("Do you want to save this process ?")){
          const result = getExcelData();
          const data = new FormData();
          data.append('process_id', localStorage.getItem("process_id"));
          data.append('device_id', AuthServices.getDeviceId());
          data.append('type', CoreService.getProcessType());
          data.append('data_file', result, "input.csv");
          ProcessService.uploadData(data).then(res => {
              toast.success("upload successfully", {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 500, hideProgressBar: false});
              onCompleted(true);
          }).catch((e)=>{
              toast.error(e.response.data.status, {position: toast.POSITION.BOTTOM_RIGHT, autoClose: 2000, hideProgressBar: false});
          })
      }
    }

    function getExcelData() {
      const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
      const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
      const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {row[field] = apiRef.current.getCellParams(id, field).value;});
        return row;
      });
      const rows = data.map((row) => {
        const mRow = {};
        for (const key of cols) {
            if (key.field !== "id"){
                mRow[key.field] = row[key.field]
            }
        }
        return mRow;
      });
      const columnNames = cols.map((col)=>{
          if (col.field !== "id") {
              return col.field;
          }
      });
      const worksheet = XLSX.utils.json_to_sheet(rows);
      XLSX.utils.sheet_add_aoa(worksheet, [[...columnNames]], {origin: 'A1'});
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ali");
      const result = XLSX.write(workbook, { compression: true, type:'buffer', bookType:'csv' });
      // const buf = new ArrayBuffer(result.length);
      // const view = new Uint8Array(buf);
      // for (let i = 0; i < result.length; i++) {
      //     view[i] = result.charCodeAt(i) & 0xff;
      // }
      return new Blob([result]);
    }


  return (
    <Box sx={{ height: "auto", width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Stack direction="row" spacing={2} sx={{"marginTop":"1rem"}}>
          <SoftButton color={"error"} size="medium" onClick={handleDeleteRow}>
            <Icon>delete</Icon>&nbsp; Delete Selected row(s)
          </SoftButton>
          <SoftButton color={"info"}  size="medium" onClick={handleAddRow}>
            <Icon>add</Icon> &nbsp; Add a row
          </SoftButton>
          <SoftButton color={"success"}  size="medium" onClick={handleExport}>
            <Icon>done</Icon> &nbsp; Save Changes
          </SoftButton>
        </Stack>
        <Box sx={{ height: "auto", mt: 1 }}>
          <DataGridPro
            initialState={{columns:{columnVisibilityModel:{id:false}}}}
            columns={cols}
            getRowId={(row)=>row["Segment Number"]}
            rows={rows}
            loading={rows.length === 0}
            rowHeight={38}
            checkboxSelection={true}
            disableSelectionOnClick={false}
            rowSelection={true}
            disableAutosize={true}
            autoHeight={true}
            apiRef={apiRef}
            showCellVerticalBorder={true}
            showColumnVerticalBorder={true}
            onRowSelectionModelChange={(ids, data) => setSelectedIds(ids)}
            experimentalFeatures={{
                newEditingApi: true,
                lazyLoading: true,
                ariaV7:true,
                columnGrouping:true,
                warnIfFocusStateIsNotSynced:true
            }}
          />
        </Box>
      </Box>


    </Box>
  );
}